import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Dialog,
  DialogContent,
  OutlinedInput,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import pic from "../../assets/gesh.png";
import bgPattern from "../../assets/images/login/bg.svg";
import OnboardingCompleteSVG from "../../assets/Onboarding.svg";
import VideoPlayer from "../common/VideoPlayer";
import {
  getCountries,
  getEmpCount,
  getIndustries,
  getSectors,
  saveOrg,
} from "../../api/auth";
import {
  currencyDisabledStyles,
  selectStyles,
  textFieldStyles,
} from "../../styles/inputStyles";
import SuccessAnimation from "../common/SuccessAnimation";
import useFetch from "../../utils/hooks/useFetch";
import api from "../../api";
import { industryEndpoints } from "../../api/endpoints/industry.endpoints";

const OwnerSetUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logo = location.state;
  const [countList, setCountList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [open, setOpen] = useState(false);
  // const [revenueOptions, setRevenueOptions] = useState([]);

  const { data: revenueList, error, loading } = useFetch("/annual-revenue");

  const currencies = [
    {
      value: "AED",
      label: "AED",
    },
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "EUR",
      label: "EUR",
    },
    {
      value: "INR",
      label: "INR",
    },
    {
      value: "CHF",
      label: "CHF",
    },
    {
      value: "CAD",
      label: "CAD",
    },
  ];
  const countriesCurrency = {
    UAE: "AED",
    USA: "USD",
    India: "INR",
    Switzerland: "CHF",
    Canada: "CAD",
  };

  const [employee, setEmployee] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [sector, setSector] = useState("");
  const [name, setName] = useState("");
  const [industry, setIndustry] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (
      name !== "" &&
      employee !== "" &&
      country !== "" &&
      amount !== "" &&
      sector !== "" &&
      industry !== ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, employee, country, amount, sector, industry]);

  useEffect(() => {
    setCurrency(countriesCurrency[country]);
  }, [country]);

  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // getSectors();
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleAmountChange = (event) => {
    // if
    setAmount(event.target.value);
  };

  const handleSectorChange = async (event) => {
    setSector(event.target.value);

    // CALL API ON SECTOR CHANGE
    const res = await api.get(
      industryEndpoints.getIndustriesById(event.target.value)
    );
    setIndustryList(res.data);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const [formError, setFormError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setOpen(true);

    // // navigate("/owner/invite");

    const formData = new FormData();
    formData.append("organization_name", name);
    if (logo) {
      formData.append("organization_logo", logo);
    }
    formData.append("organization_employeeCount", employee);
    formData.append("organization_country", country);
    formData.append("organization_averageRevenue", amount);
    formData.append("organization_sector", sector);
    formData.append("organization_industry", industry);
    console.log(logo);
    try {
      let response = await saveOrg(formData);
      console.log("saveOrg", response);
      setOpen(true);
    } catch (error) {
      console.log(error);
      setFormError(error?.message);
    }
  };
  async function getAllCountries() {
    const response = await getCountries();
    if (response?.status === 200) {
      setCountryList(response.data);
    } else {
    }
  }

  // async function getAllIndustries() {
  //   const response = await getIndustries();
  //   if (response?.status === 200) {
  //     console.log(response.data);
  //     setIndustryList(response.data);
  //   } else {
  //   }
  // }
  async function getAllSectors() {
    const response = await getSectors();
    if (response?.status === 200) {
      setSectorList(response.data);
    } else {
    }
  }
  async function getAllEmpCount() {
    const response = await getEmpCount();
    if (response?.status === 200) {
      setCountList(response.data);
    } else {
    }
  }
  useEffect(() => {
    getAllCountries();
    getAllSectors();
    getAllEmpCount();
    // getAllIndustries();
  }, []);

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container style={{ height: "100%", width: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            // backgroundImage: `linear-gradient(to bottom, rgba(0,26,0,0.5), rgba(0,26,0,0.5)), url(${nursery})`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <VideoPlayer />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={bgPattern}
            alt=""
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              zIndex: "1",
              width: "400px",
              height: "400px",
            }}
          />
          <Box
            style={{
              maxWidth: "450px",
              width: "100%",
              padding: "20px",
              position: "relative",
              zIndex: "2",
            }}
          >
            <img
              src={pic}
              alt="Logo"
              style={{ width: "90px", marginBottom: "15px" }}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{
                fontFamily: "Inter",
                fontSize: "28px",
                fontWeight: 500,
                marginBottom: ".75rem",
              }}
            >
              Setup your Organization!
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginBottom: "2rem",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: 15,
                color: "#8692A6",
              }}
            >
              Join us to access sustainability reports and track your progress
              towards a greener future.
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                size="small"
                fullWidth
                label="Company Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                // sx={{
                //   backgroundColor: "white",

                //   fontSize: "14px",
                //   "& .MuiOutlinedInput-root": {
                //     "&.Mui-focused fieldset": {
                //       borderColor: "#369D9C",
                //     },
                //   },
                //   "& .MuiInputLabel-root.Mui-focused": {
                //     color: "#369D9C", // Focused label color
                //   },
                //   "& .MuiFormHelperText-root": {
                //     color: "red", // Custom helper text color
                //   },
                //   "& .MuiInputBase-input": {
                //     fontFamily: "Inter",
                //   },
                //   "& .MuiInputLabel-root": {
                //     fontFamily: "Inter",
                //     fontSize: "14px",
                //   },
                //   input: {
                //     fontSize: "14px",
                //   },
                // }}
                sx={textFieldStyles}
              />
              <FormControl
                size="small"
                style={{ width: "49%", marginTop: "1rem" }}
                // sx={{
                //   backgroundColor: "white",

                //   fontSize: "14px",
                //   "& .MuiOutlinedInput-root": {
                //     "&.Mui-focused fieldset": {
                //       borderColor: "#369D9C",
                //     },
                //   },
                //   "& .MuiInputLabel-root.Mui-focused": {
                //     color: "#369D9C", // Focused label color
                //   },
                //   "& .MuiFormHelperText-root": {
                //     color: "red", // Custom helper text color
                //   },
                //   "& .MuiInputBase-input": {
                //     fontFamily: "Inter",
                //   },
                //   "& .MuiInputLabel-root": {
                //     fontFamily: "Inter",
                //     fontSize: "14px",
                //   },
                //   input: {
                //     fontSize: "14px",
                //   },
                //   ".MuiSelect-select": {
                //     fontSize: "14px",
                //   },
                // }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: "14px",
                    mt: "3px",
                    color: "#787878", // Default color
                    "&.Mui-focused": {
                      color: "#787878", // Ensure the color remains the same when focused
                    },
                  }}
                >
                  Company Size
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Company Size"
                  value={employee}
                  onChange={handleEmployeeChange}
                  sx={selectStyles}
                >
                  {countList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      sx={{ fontFamily: "Inter" }}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                size="small"
                style={{
                  width: "49%",
                  marginTo: "1rem",
                  marginLeft: "2%",
                  marginTop: "1rem",
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    fontSize: "14px",
                    mt: "3px",
                    color: "#787878", // Default color
                    "&.Mui-focused": {
                      color: "#787878", // Ensure the color remains the same when focused
                    },
                  }}
                >
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Country"
                  value={country}
                  onChange={handleCountryChange}
                  sx={selectStyles}
                >
                  {countryList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      sx={{ fontFamily: "Inter" }}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "1rem",
                  gap: "10px",
                }}
              >
                {/* <TextField
                  id="currency"
                  type="text"
                  disabled
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                  onKeydown={(e) => {
                    e.preventDefault();
                  }}
                  sx={currencyDisabledStyles}
                  style={{ width: "35%", color: "#212121 !important" }}
                  onChange={handleCurrencyChange}
                  value={
                    countryList.find((item) => item.id === country)?.currency ||
                    ""
                  }
                  label="Currency"
                /> */}

                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="revenueSelect"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878", // Default color
                      "&.Mui-focused": {
                        color: "#787878", // Ensure the color remains the same when focused
                      },
                    }}
                  >
                    Annual Revenue
                  </InputLabel>
                  <Select
                    labelId="amount-select"
                    id="demo-amount-select"
                    label="Annual Revenue"
                    value={amount}
                    onChange={handleAmountChange}
                    sx={selectStyles}
                  >
                    {revenueList?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* 
              <FormControl sx={{ marginTop: "1rem", color: "red" }} fullWidth>
                <InputLabel
                  sx={{
                    fontSize: "14px",
                    color: "#787878", // Default color
                    "&.Mui-focused": {
                      color: "#787878", // Ensure the color remains the same when focused
                    },
                  }}
                  htmlFor="outlined-adornment-amount"
                >
                  Annual Revenue
                </InputLabel>
                <OutlinedInput
                  onKeyDown={(e) => {
                    if (e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  type="number"
                  onChange={handleAmountChange}
                  sx={textFieldStyles}
                  id="outlined-adornment-amount"
                  startAdornment={
                    country ? (
                      <InputAdornment
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                          },
                        }}
                        position="start"
                      >
                        {
                          countriesCurrency[
                            countryList.find((item) => item.id === country)
                              ?.name
                          ]
                        }
                      </InputAdornment>
                    ) : null
                  }
                  label="Amount"
                />
              </FormControl> */}

              {/* <div
                style={{
                  margin: "1.5rem 0  0 ",
                  borderBottom: "1px solid #E0E0E0",
                }}
              ></div> */}

              <FormControl size="small" fullWidth style={{ marginTop: "1rem" }}>
                <InputLabel
                  id="sector-select"
                  sx={{
                    fontSize: "14px",
                    mt: "3px",
                    color: "#787878", // Default color
                    "&.Mui-focused": {
                      color: "#787878", // Ensure the color remains the same when focused
                    },
                  }}
                >
                  Sector
                </InputLabel>
                <Select
                  labelId="sector-select"
                  id="demo-sector-select"
                  label="Sector"
                  value={sector}
                  onChange={handleSectorChange}
                  sx={selectStyles}
                >
                  <MenuItem disabled value={""} sx={{ fontFamily: "Inter" }}>
                    Select Industry
                  </MenuItem>
                  {sectorList.map((item, index) => (
                    <MenuItem
                      disabled={
                        item?.name !== "Extractives & Minerals Processing"
                          ? true
                          : false
                      }
                      key={index}
                      value={item?.id}
                      sx={{ fontFamily: "Inter" }}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                size="small"
                fullWidth
                style={{ marginTop: "1rem" }}
                // sx={{
                //   fontSize: "14px",
                //   backgroundColor: "white",

                //   "& .MuiOutlinedInput-root": {
                //     "&.Mui-focused fieldset": {
                //       borderColor: "#369D9C",
                //     },
                //   },
                //   "& .MuiInputLabel-root.Mui-focused": {
                //     color: "#369D9C", // Focused label color
                //   },
                //   "& .MuiFormHelperText-root": {
                //     color: "red", // Custom helper text color
                //   },
                //   "& .MuiInputBase-input": {
                //     fontFamily: "Inter",
                //   },
                //   "& .MuiInputLabel-root": {
                //     fontFamily: "Inter",
                //     fontSize: "14px",
                //   },
                //   input: {
                //     fontSize: "14px",
                //   },
                // }}
              >
                <InputLabel
                  id="industry-select"
                  sx={{
                    fontSize: "14px",
                    mt: "3px",
                    color: "#787878", // Default color
                    "&.Mui-focused": {
                      color: "#787878", // Ensure the color remains the same when focused
                    },
                  }}
                >
                  Industry
                </InputLabel>
                <Select
                  labelId="industry-select"
                  id="industry-select"
                  label="Industry"
                  value={industry}
                  onChange={handleIndustryChange}
                  sx={selectStyles}
                >
                  {!industryList.length ? (
                    <MenuItem disabled value={""} sx={{ fontFamily: "Inter" }}>
                      Select Sector First
                    </MenuItem>
                  ) : (
                    <MenuItem disabled value={""} sx={{ fontFamily: "Inter" }}>
                      Select Industry
                    </MenuItem>
                  )}
                  {industryList.map((item, index) => (
                    <MenuItem
                      disabled={
                        item?.name !== "Oil & Gas – Exploration & Production"
                          ? true
                          : false
                      }
                      key={index}
                      value={item?.id}
                      sx={{ fontFamily: "Inter" }}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formError && (
                <Typography
                  sx={{
                    color: "#F51934",
                    fontSize: "13px",
                    fontWeight: 500,
                    mt: "3px",
                  }}
                >
                  {formError}
                </Typography>
              )}

              <button
                disabled={!isFormValid}
                type="submit"
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  padding: "14px 24px",

                  borderRadius: 6,
                  // background: "#E8E8E8",
                  background: isFormValid
                    ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                    : "#A0A0A0",
                  // backgroundImage: isFormValid
                  //   ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                  //   : "black",
                  fontWeight: 500,
                  fontSize: "15px",
                  fontFamily: "Inter",
                  // color: !isFormValid ? "#A2A2A2" : "#FFF",
                  color: !isFormValid ? "#fff" : "#fff",
                  border: "1px solid #DDD",
                  letterSpacing: "0.5px",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </form>
            <Dialog
              open={open}
              onClose={handleClose}
              sx={{
                ".MuiBackdrop-root": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  // backdropFilter: "blur(6px)",
                },
                ".MuiPaper-root": {
                  borderRadius: "10px",
                },
              }}
            >
              <DialogContent style={{ width: "550px", p: 0 }}>
                <Box
                  style={{
                    textAlign: "center",
                    padding: "2rem 4rem 3rem 4rem",
                    borderRadius: "10px",
                    backgroundColor: "#FFF",
                  }}
                >
                  {/* <img
                    src={OnboardingCompleteSVG}
                    alt="Onboarding Complete"
                    width="234"
                    height="179"
                  /> */}

                  <SuccessAnimation />

                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Inter",
                      marginTop: "1.5rem",
                    }}
                  >
                    Onboarding Complete
                  </Typography>
                  <Button
                    onClick={() => {
                      navigate("/");
                    }}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      mt: 2,
                      background:
                        "linear-gradient(102deg, #369d9c 0%, #28814d 100%);",
                      color: "#fff",
                      padding: 1.5,
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                  >
                    Go to Dashboard
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OwnerSetUp;
