import React, { useState } from "react";
import DoughnutChart from "./DoughnutChart";
import { Box, Grid, Typography } from "@mui/material";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import LineAreaGraph from "./LineAreaGraph";
import HorizontalBarChart from "./HorizontalBarChart";
import gross from "../../assets/sustainabilityReport/gross.svg";
import newSuppliers from "../../assets/sustainabilityReport/newSuppliers.svg";
import newTech from "../../assets/sustainabilityReport/newTech.svg";
import CSR from "../../assets/sustainabilityReport/CSR.svg";
import SelectFilter from "./SelectFilter";
import LevelDoughnutChart from "./LevelDoughnutChart";
import { convertDecimalToYearsAndMonths } from "../../utils/decimalToYears";
import employees from "../../assets/sustainabilityReport/employees.svg";
import {
  transformDataForComplaints,
  transformGovernanceTrendData,
} from "../../utils/reportPage";

const GovStatsCard = ({ icon, title, amount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#FFF5E5",
        p: "20px",
        borderRadius: "12px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={28}
        width={28}
        style={{ marginBottom: "6px" }}
      />
      <h4 className="base-sector-card_title">{title}</h4>
      <Typography variant="h5" sx={{ fontSize: "18px", marginTop: "auto" }}>
        {amount?.toLocaleString("en-IN")}
      </Typography>
    </Box>
  );
};

const GovernanceStats = ({ report }) => {
  const { governanceKPI, basicSectorSpecificKPI, socialKPI } = report;
  const avgEmpTenure = convertDecimalToYearsAndMonths(
    socialKPI?.S4?.averageTenure?.value
  );

  const last6yearsReportdata = [report, ...report?.last6YearsReports];
  console.log(last6yearsReportdata);

  const cardData = [
    {
      id: 0,
      icon: gross,
      title: "Gross Revenue (in USD)",
      amount: `${basicSectorSpecificKPI?.B1?.grossRevenue?.value} Million`,
    },
    {
      id: 1,
      icon: gross,
      title: "% of Revenue spent on local suppliers",
      amount: governanceKPI?.G3?.localSuppliersSpending?.value,
    },
    {
      id: 2,
      icon: newSuppliers,
      title: "% of Revenue spent on new suppliers",
      amount: governanceKPI?.G4?.newSuppliersSpending?.value,
    },
    {
      id: 3,
      icon: newTech,
      title: "% of Revenue spent on new/innovative technologies",
      amount: governanceKPI?.G6?.innovativeTechnologiesSpending?.value,
    },
    {
      id: 4,
      icon: CSR,
      title: "% of Revenue spent on CSR activities",
      amount: `${governanceKPI?.G10?.csrSpending?.value?.toLocaleString(
        "en-IN"
      )}`,
    },
    // {
    //   id: 5,
    //   icon: employees,
    //   title: "% Average Employee Tenure",
    //   amount: socialKPI?.S4?.averageTenure?.value,
    // },
  ];

  const avgEmployeeTenureDoughnut = [
    { label: "Average Tenure", value: socialKPI?.S4?.averageTenure?.value },
  ];

  const data = [
    { label: "SCOPE 1", value: 50 },
    { label: "SCOPE 2", value: 30 },
    { label: "SCOPE 3", value: 30 },
  ];

  // const horizontalData = [
  //   {
  //     label: "Q1 2023",
  //     "Waste Water Treated": 20,
  //     "Waste Water Generated": 80,
  //   },
  //   {
  //     label: "Q2 2023",
  //     "Waste Water Treated": 30,
  //     "Waste Water Generated": 70,
  //   },
  //   {
  //     label: "Q3 2023",
  //     "Waste Water Treated": 25,
  //     "Waste Water Generated": 75,
  //   },
  //   {
  //     label: "Q4 2023",
  //     "Waste Water Treated": 80,
  //     "Waste Water Generated": 20,
  //   },
  //   {
  //     label: "Q1 2024",
  //     "Waste Water Treated": 65,
  //     "Waste Water Generated": 35,
  //   },
  //   {
  //     label: "Q2 2024",
  //     "Waste Water Treated": 45,
  //     "Waste Water Generated": 55,
  //   },
  //   {
  //     label: "Q3 2024",
  //     "Waste Water Treated": 64,
  //     "Waste Water Generated": 36,
  //   },
  // ];

  const horizontalData = [
    {
      label: "Q1 2023",
      "Complaints Resolved": 20,
      "Complaints Un-resolved": 80,
    },
  ];

  const formattedComplaintsData = transformDataForComplaints(
    last6yearsReportdata?.slice(-6)
  );

  const barData = [
    { quarter: "Q1 2023", value: 10 },
    { quarter: "Q2 2023", value: 20 },
    { quarter: "Q3 2023", value: 15 },
    { quarter: "Q4 2023", value: 25 },
    { quarter: "Q1 2024", value: 30 },
    { quarter: "Q2 2024", value: 25 },
    { quarter: "Q3 2024", value: 35 },
    { quarter: "Q4 2024", value: 45 },
    { quarter: "Q1 2025", value: 50 },
    { quarter: "Q2 2025", value: 40 },
    { quarter: "Q3 2025", value: 45 },
  ];

  const colors = ["#02B880", "#E1F4F3", "#B1E9D8"];

  const governanceTrendOptions = [
    {
      name: "Proportion of Revenue spent on local suppliers",
      value: "localSuppliersSpending",
    },
    {
      name: "Proportion of Revenue spent on new suppliers",
      value: "newSuppliersSpending",
    },
    {
      name: "Proportion of Revenue spent on new/innovative technologies",
      value: "innovativeTechnologiesSpending",
    },
    {
      name: "Proportion of Revenue spent on CSR activities",
      value: "csrSpending",
    },
  ];

  const [trendFilter, setTrendFilter] = useState(
    governanceTrendOptions[0]?.value
  );

  const handleGovernanceTrendChange = (e) => {
    setTrendFilter(e.target.value);
  };

  const formattedGovernanceKpiData = transformGovernanceTrendData(
    last6yearsReportdata?.slice(-6),
    trendFilter
  );

  return (
    <>
      <h1 className="sustainability-section_heading">Governance Index</h1>
      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {cardData.map((item) => (
          <Grid key={item.id} item xs={2.4}>
            <GovStatsCard {...item} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid item xs={5.5}>
          <Box
            sx={{
              p: "16px 16px 32px 16px",
              display: "flex",
              flexDirection: "column",
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <h2>Governance KPIs trends</h2>
              <SelectFilter
                onChange={handleGovernanceTrendChange}
                options={governanceTrendOptions}
                value={trendFilter}
                placeholder={"Select Trend"}
              />
            </div>
            <div
              style={{
                height: "260px",
                width: "100%",
                border: "1px solid #EFEFEF",
              }}
            >
              <ParentSize>
                {({ width, height }) => (
                  <LineAreaGraph
                    lineColor={"#FFCB77"}
                    height={height}
                    width={width}
                    gradientFrom={"#f7c575"}
                    gradientTo={"#FFFFFF"}
                    leftLabel={"Percentage(%)"}
                    tooltipLabel={
                      governanceTrendOptions?.find(
                        (item) => item?.value === trendFilter
                      )?.name
                    }
                    unit="%"
                    data={formattedGovernanceKpiData}
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>Percentage of complains resolved (%)</h2>
            <div style={{ height: "260px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <HorizontalBarChart
                    colors={["#FFCB77", "#FFF6E7"]}
                    data={formattedComplaintsData}
                    width={width}
                    height={height}
                    showLegend={false}
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>Average Employee Tenure</h2>
            <div style={{ height: "227px", marginTop: "-10px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <LevelDoughnutChart
                    height={height}
                    width={width}
                    maxValue={10}
                    valueProp={socialKPI?.S4?.averageTenure?.value}
                    thresholds={{ low: 3, medium: 6, high: 5 }}
                    centerText={avgEmpTenure.years}
                    subText={`Years & ${avgEmpTenure.months} months`}
                    colors={["#FFF4E3", "#FFE0AD", "#FFCB77"]}
                    legend={[
                      { label: "Low (0 < 3 Years)", color: "#FFF4E3" },
                      { label: "Medium (3 < 6 Years)", color: "#FFE0AD" },
                      { label: "High (Above then 5 Years)", color: "#FFCB77" },
                    ]}
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GovernanceStats;
