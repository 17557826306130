import React, { useRef, useState } from "react";
import { Grid, Box, Typography, Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UploadLogo from "./UploadLogo";
import pic from "../../assets/gesh.png";
import jungle from "../../assets/jungle.jpg";
import bgPattern from "../../assets/images/login/bg.svg";
import VideoPlayer from "../common/VideoPlayer";
import ImageModal from "../common/ImageModal";
import defaultUser from "../../assets/images/login/defaultUser.png";
import defaultOrg from "../../assets/defaultOrg.svg";
import useFetch from "../../utils/hooks/useFetch";

const SetupOrg = () => {
  const { data: onboardingData, error: onBoardingErr } =
    useFetch("/user/onboard-data");
  console.log(onboardingData);

  const navigate = useNavigate();
  const [LogoSet, setLogoSet] = useState(false);
  const [logo, setLogo] = useState(null);
  const [openImageResizer, setOpenImageResizer] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [imageApi, setImageApi] = useState(null); // Represents the uploaded image
  const imageInput = useRef();
  const [key, setKey] = useState(0);
  const [originalImage, setOriginalImage] = useState("");
  const [croppedImageFile, setCroppedImageFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    navigate("/setup", { state: croppedImageFile });
    console.log(croppedImageFile);
  };

  const clickInput = () => {
    imageInput.current && imageInput.current.click();
  };

  const handleAvatarClick = () => {
    if (imageApi) {
      // If image is already uploaded, open the modal to resize/edit
      setOpenImageResizer(true);
      setUserProfile(originalImage); // Set the image to be edited in the modal
    } else {
      // Otherwise, trigger file input
      clickInput();
    }
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 5 * 1024 * 1024) {
        alert("File size should not exceed 5MB.");
        e.target.value = ""; // Reset the file input
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        console.log(reader.result?.toString());
        setUserProfile(reader.result?.toString() || "");
        setOpenImageResizer(true); // Open the modal after the file is selected
        setOriginalImage(reader.result?.toString() || "");
      });
      reader.readAsDataURL(e.target.files[0]);

      // Reset the file input
      e.target.value = "";
      setKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Grid container style={{ height: "100%", width: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <VideoPlayer />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={bgPattern}
            alt=""
            height={400}
            width={400}
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              zIndex: "1",
            }}
          />
          <Box
            style={{
              maxWidth: "500px",
              width: "100%",
              padding: "20px",
              position: "relative",
              zIndex: "2",
            }}
          >
            <img
              src={pic}
              alt="Logo"
              style={{ width: "100px", marginBottom: "20px" }}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{
                fontFamily: "Inter",
                fontSize: "25px",
                fontWeight: 500,
                marginBottom: "1.2rem",
              }}
            >
              Setup your Organization Profile!
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginTop: "16px",
                marginBottom: "3rem",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "1rem",
                color: "#8692A6",
                lineHeight: "1.5rem",
              }}
            >
              Join us to access sustainability reports and track your progress
              towards a greener future.
            </Typography>

            <Box
              sx={{
                mb: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ImageModal
                open={openImageResizer}
                setOpen={setOpenImageResizer}
                imageUrl={userProfile}
                setImageUrl={setUserProfile}
                setImageApi={setImageApi}
                setCroppedImage={setCroppedImageFile}
              />
              <Avatar
                onClick={handleAvatarClick} // If imageApi exists, open modal; otherwise open file selector
                alt="placeholder"
                src={!imageApi ? defaultOrg : imageApi}
                sx={{
                  width: 75, // Avatar width
                  height: 75, // Avatar height
                  border: "1px solid #43BAB9",
                  cursor: "pointer",
                  img: {
                    width: !imageApi ? "60%" : "100%", // Reduce the image size inside the avatar
                    height: !imageApi ? "60%" : "100%", // Maintain aspect ratio
                    objectFit: "cover", // Ensure the image covers its area proportionally
                  },
                }}
              />
              <Button
                variant="outlined"
                sx={{
                  color: "#26203B",
                  border: "1px solid #EEEEEE",
                  borderRadius: "8px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  height: "max-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: ".5rem",
                  p: ".5rem .9rem",
                  textTransform: "none",
                  mt: "1.25rem",
                  mb: "1.5rem",
                  fontSize: "14px",
                }}
                onClick={clickInput} // Always open file selector on upload button click
              >
                <svg width="22" height="22" viewBox="0 0 25 24" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.868 8.79442C8.282 8.79442 8.618 9.13042 8.618 9.54442C8.618 9.95842 8.282 10.2944 7.868 10.2944H6.935C5.316 10.2944 4 11.6104 4 13.2284V18.1034C4 19.7224 5.316 21.0384 6.935 21.0384H18.065C19.683 21.0384 21 19.7224 21 18.1034V13.2194C21 11.6064 19.688 10.2944 18.076 10.2944H17.133C16.719 10.2944 16.383 9.95842 16.383 9.54442C16.383 9.13042 16.719 8.79442 17.133 8.79442H18.076C20.515 8.79442 22.5 10.7794 22.5 13.2194V18.1034C22.5 20.5494 20.51 22.5384 18.065 22.5384H6.935C4.49 22.5384 2.5 20.5494 2.5 18.1034V13.2284C2.5 10.7834 4.49 8.79442 6.935 8.79442H7.868ZM13.0306 2.22202L15.9466 5.15002C16.2386 5.44402 16.2376 5.91802 15.9446 6.21002C15.6506 6.50202 15.1766 6.50202 14.8846 6.20802L13.249 4.56677L13.2496 15.5413H11.7496L11.749 4.56677L10.1156 6.20802C9.9696 6.35602 9.7766 6.42902 9.5846 6.42902C9.3936 6.42902 9.2016 6.35602 9.0556 6.21002C8.7626 5.91802 8.7606 5.44402 9.0536 5.15002L11.9686 2.22202C12.2496 1.93902 12.7496 1.93902 13.0306 2.22202Z"
                    fill="#26203B"
                  />
                </svg>
                {userProfile ? "Re-upload logo" : "Upload logo"}
                <input
                  key={key}
                  type="file"
                  hidden
                  accept="image/png, image/jpeg"
                  ref={imageInput}
                  onChange={onFileChange}
                />
              </Button>
            </Box>

            <button
              onClick={handleSubmit}
              type="submit"
              style={{
                marginTop: "1.5rem",
                width: "100%",
                padding: "14px 24px",
                borderRadius: 6,
                background: !imageApi ? "#9F9F9F" : "",
                backgroundImage: imageApi
                  ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                  : "",
                fontWeight: 500,
                fontSize: "16px",
                fontFamily: "Inter",
                color: !imageApi ? "#fff" : "#FFF",
                border: "1px solid #DDD",
                letterSpacing: "0.5px",
                cursor: "pointer",
              }}
            >
              Next
            </button>
            <button
              onClick={handleSubmit}
              type="submit"
              style={{
                marginTop: ".5rem",
                width: "100%",
                padding: "12px 24px",
                border: "none",
                background: "none",
                fontWeight: 500,
                fontSize: "16px",
                fontFamily: "Inter",
                color: "#369D9C",
                cursor: "pointer",
              }}
            >
              Skip
            </button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetupOrg;
