import React from "react";
import useFetch from "../../../utils/hooks/useFetch";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { convertDateToDDMMYYYY } from "../../../utils/dateFunctions";
import { Link } from "react-router-dom";

const SentForVerification = ({ search }) => {
  const {
    data: verificationData,
    loading: verificationLoading,
    error: verificationError,
  } = useFetch(reportEndpoints.getReports, {
    status: "sendForVerification",
  });

  console.log(verificationData);

  const rows = [
    {
      id: 0,
      reportName: "Report 1",
      submittedDate: "05-05-2024",
      status: "Verified",
    },
    {
      id: 1,
      reportName: "Report 2",
      submittedDate: "10-05-2024",
      status: "Not Verified",
    },
  ];

  const headings = ["Report Name", "Submitted Date", "Status", "Action"];

  const filteredReports = verificationData?.reports?.filter((item) =>
    item?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  return (
    <div className="reports-table-container">
      <div className="reports-table">
        <div className="reports-table-row reports-table-header">
          {headings.map((item) => (
            <div key={item} className="reports-table-cell">
              {item}
            </div>
          ))}
        </div>
        {filteredReports?.map((item) => {
          const status = {
            sendForVerification: "Sent for Vetting",
            rejected: "Rejected",
          };
          return (
            <div key={item.id} className="reports-table-row">
              <div className="reports-table-cell">{item?.name}</div>
              <div className="reports-table-cell">
                {convertDateToDDMMYYYY(item?.sendForVerificationDate)}
              </div>
              <div
                className={`reports-table-cell ${
                  item.status !== "sendForVerification"
                    ? "report-unverified"
                    : "report-verified"
                }`}
              >
                {status[item?.status]}
              </div>
              <div className="reports-table-cell">
                <Link
                  to={
                    item?.status === "sendForVerification"
                      ? `/score-card/${item?.id}`
                      : `/rejected/${item?.id}`
                  }
                  target="_blank"
                >
                  <button className="report-actions-btn">Preview</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SentForVerification;
