import { io } from "socket.io-client";
// import { backendUrl as baseURL } from "../../config";
import constant from "../../constant";

const baseURL = constant.BACKEDN_BASE_URL;

let socket = null;

export const initializeSocket = (callback) => {
  console.log("initialized");

  const token = localStorage.getItem("token");
  console.log(baseURL, token, "url,token");

  socket = io(baseURL, {
    auth: {
      token: token, // Include the token here
    },
  });

  socket.on("message", (message) => {
    console.log("socket message", message);
    callback(message);
  });
};

// export const newMessage = (callback) => {
//   console.log("initalize socket new message");
//   if (!socket) {
//     initializeSocket();
//   }

//   socket.on("newMessage", (message) => {
//     console.log("emit message 2");
//     callback(message);
//   });
// };
