import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import totalElectricity from "../../../assets/sustainabilityReport/totalElectricity.svg";
import totalSox from "../../../assets/sustainabilityReport/totalSOX.svg";
import totalNOX from "../../../assets/sustainabilityReport/totalNOX.svg";
import totalGHG from "../../../assets/sustainabilityReport/totalGHG.svg";
import waterDrops from "../../../assets/sustainabilityReport/waterDrops.svg";
import PieChart from "../PieChart";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import HalfDoughnutChart from "../HalfDoughnutChart";
import DoughnutChart from "../DoughnutChart";

const EnvStatsCard = ({ icon, title, amount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#E6F8F2",
        p: "1.25rem",
        borderRadius: "12px",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={30}
        width={30}
        style={{ marginBottom: "6px" }}
      />
      <h4 className="base-sector-card_title">{title}</h4>
      <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: 500 }}>
        {amount?.toLocaleString("en-IN")}
      </Typography>
    </Box>
  );
};

const EnvironmentalZeroState = ({ report }) => {
  const { environmentKPI, basicSectorSpecificKPI } = report;

  const cardData = [
    {
      id: 0,
      icon: totalGHG,
      title: "Total GHG emissions (Million tCO2e)",

      amount:
        environmentKPI?.E1?.scope1Emissions?.value +
        environmentKPI?.E1?.scope1Emissions?.value +
        environmentKPI?.E2?.scope3Emissions?.value,
    },
    {
      id: 1,
      icon: totalSox,
      title: "Total SOx emissions (Million tonnes)",
      amount: report?.environmentKPI?.E6?.soxReleased?.value,
    },
    {
      id: 2,
      icon: totalNOX,
      title: "Total NOx emissions (Million tonnes)",
      amount: report?.environmentKPI?.E5?.noxReleased?.value,
    },
    {
      id: 3,
      icon: totalElectricity,
      title: "Total Electricity Consumption (MWh)",
      amount:
        report?.basicSectorSpecificKPI?.B6?.totalElectricityConsumption?.value,
    },
  ];

  const colors = ["#02B880", "#B1E9D8", "#E1F4F3"];

  const emissionsPie = [
    { label: "Scope 1", value: environmentKPI?.E1?.scope1Emissions?.value },
    { label: "Scope 2", value: environmentKPI?.E1?.scope2Emissions?.value },
    { label: "Scope 3", value: environmentKPI?.E2?.scope3Emissions?.value },
  ];

  const electricityHalfDoughnut = [
    {
      label: "Percentage of clean electricity",
      value: basicSectorSpecificKPI?.B6?.electricityFromRenewables?.value,
    },
    {
      label: "Percentage of electricity from conventional sources",
      value: basicSectorSpecificKPI?.B6?.electricityFromGrid?.value,
    },
  ];

  const wasteTreatmentPie = [
    {
      label: "Incineration",
      value:
        basicSectorSpecificKPI?.B3?.totalHazardousWaste?.value +
        basicSectorSpecificKPI?.B4?.totalNonHazardousWaste?.value -
        environmentKPI?.E4?.wasteToLandfill?.value,
    },
    { label: "Landfill", value: environmentKPI?.E4?.wasteToLandfill?.value },
  ];

  const wasteGenerationPie = [
    {
      label: "Hazardous Waste",
      value: basicSectorSpecificKPI?.B3?.totalHazardousWaste?.value,
    },
    {
      label: "Non-Hazardous Waste",
      value: basicSectorSpecificKPI?.B4?.totalNonHazardousWaste?.value,
    },
  ];

  const wasteWaterTreatedDoughnut = [
    {
      label: "Waste Water Treated",
      value: environmentKPI?.E9?.wasteWaterTreated?.value,
    },
    {
      label: "Waste Water Generated",
      value:
        environmentKPI?.E9?.totalWasteWater?.value -
        environmentKPI?.E9?.wasteWaterTreated?.value,
    },
  ];

  const percentOfWaterTeated =
    (environmentKPI?.E9?.wasteWaterTreated?.value /
      environmentKPI?.E9?.totalWasteWater?.value) *
    100;

  return (
    <>
      <h1 className="sustainability-section_heading">Environmental Index </h1>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {cardData.map((item) => (
          <Grid key={item.id} item xs={3}>
            <EnvStatsCard {...item} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid item xs={4}>
          <Box
            sx={{
              p: "16px 16px 32px 16px",
              display: "flex",
              flexDirection: "column",
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Emissions Breakdown
              <span className="sustain-subheading-h2">(In Million tCO2e)</span>
            </h2>
            <div style={{ height: "13.5rem" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={colors}
                    data={emissionsPie}
                    width={width}
                    height={height}
                    unit="tCO2e"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>Electricity Consumption Breakdown</h2>
            <div style={{ height: "220px", marginTop: "20px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <HalfDoughnutChart
                    colors={colors}
                    data={electricityHalfDoughnut}
                    width={width}
                    height={height}
                    unit="KWh"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>Waste Treatment</h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={colors}
                    data={wasteTreatmentPie}
                    width={width}
                    height={height}
                    unit="Tonnes"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              flexGrow: 1,
              backgroundColor: "#FFF",
              borderRadius: "12px",
              // border: "1px solid black",
              height: "21rem",
            }}
          >
            <h2>Waste Generation</h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={colors}
                    data={wasteGenerationPie}
                    width={width}
                    height={height}
                    unit="Tonnes"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>Waste Water Treated</h2>
            <div style={{ height: "227px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <DoughnutChart
                    colors={colors}
                    data={wasteWaterTreatedDoughnut}
                    width={width}
                    height={height}
                    centerText={`${percentOfWaterTeated.toFixed(2)}%`}
                    subText="Percentage of total waste water recycled"
                    unit="m3"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "#E6F8F2",
              display: "flex",
              alignItems: "center",
              height: "100%",
              borderRadius: "12px",
            }}
          >
            <div>
              <img src={waterDrops} height={30} width={30} alt="Water" />
              <p
                style={{ fontSize: "1rem", fontWeight: 500, marginTop: "10px" }}
              >
                Water Consumption (million m3)
              </p>
              <p
                style={{ fontSize: "22px", fontWeight: 600, marginTop: "10px" }}
              >
                {basicSectorSpecificKPI?.B2?.totalWaterConsumption?.value}
              </p>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EnvironmentalZeroState;
