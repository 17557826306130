import React, { useEffect, useState } from "react";
import DashboardNav from "../../Dashboard/TopBar";
import "./dataPoints.css";
import back from "../../../assets/ArrowLeft.svg";
import BaseSector from "./BaseSector";
import ai from "../../../assets/AI.svg";
import EnvironmentalKpi from "./EnvironmentalKpi";
import SocialKpi from "./SocialKpi";
import GovernanceKpi from "./GovernanceKpi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeading from "../../common/PageHeading/PageHeading";
import OutlinedButton from "../../common/OutlinedButton";
import GradientButton from "../../common/GradientButton";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import useFetch from "../../../utils/hooks/useFetch";
import usePostApi from "../../../utils/hooks/usePost";
import { useTaggingStore } from "./assignments/chatStore";

const Tab = ({ name, onClick, isActive }) => {
  return (
    <div
      className={`add-data-points_tab ${isActive ? "activeTab" : ""}`}
      onClick={onClick}
    >
      <h3 className={`add-data-points_tabName ${isActive ? "active" : ""}`}>
        {name}
      </h3>
    </div>
  );
};

const AddDataPoints = () => {
  const location = useLocation();
  const params = useParams();

  const {
    data: saveResponse,
    loading: savingProgress,
    error,
    put: saveProgress,
    status: saveStatus,
  } = usePutApi(reportEndpoints.saveProgress(params.id));

  const {
    data: generateResponse,
    error: generateError,
    loading: generateLoading,
    post: generateReport,
  } = usePostApi(reportEndpoints.generate(params.id));

  const { fetchUsers } = useTaggingStore();

  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const tabs = [
    {
      id: 0,
      name: "Base: Sector Specific KPI",
      component: <BaseSector />,
    },
    {
      id: 1,
      name: "Environmental KPI",
      component: <EnvironmentalKpi />,
    },
    {
      id: 2,
      name: "Social KPI",
      component: <SocialKpi />,
    },
    {
      id: 3,
      name: "Governance KPI",
      component: <GovernanceKpi />,
    },
  ];

  function removeKeys(obj, keysToRemove) {
    if (obj === null || typeof obj !== "object") {
      return obj; // Return the value if it's not an object
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => removeKeys(item, keysToRemove));
    }

    const newObj = {};

    Object?.keys(obj)?.forEach((key) => {
      if (!keysToRemove?.includes(key)) {
        newObj[key] = removeKeys(obj[key], keysToRemove);
      }
    });

    return newObj;
  }

  function filterEmptyObjects(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj; // Return if it's not an object
    }

    if (Array.isArray(obj)) {
      return obj
        .map((item) => filterEmptyObjects(item))
        .filter((item) => item !== undefined && item !== null && item !== "");
    }

    const newObj = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (typeof value === "object" && value !== null) {
        const filteredValue = filterEmptyObjects(value);
        // Only include the key if the object or array has some valid values
        if (Object.keys(filteredValue).length > 0) {
          newObj[key] = filteredValue;
        }
      } else if (value !== undefined && value !== null && value !== "") {
        // Add non-null, non-empty, and non-undefined primitive values
        newObj[key] = value;
      }
    });

    return newObj;
  }

  const handleSaveProgress = async () => {
    const baseData = JSON.parse(localStorage.getItem("baseSectorKpiData"));
    const socialData = JSON.parse(localStorage.getItem("socialKpiData"));
    const environmentalData = JSON.parse(
      localStorage.getItem("environmentalKpiData")
    );
    const governanceData = JSON.parse(
      localStorage.getItem("governanceKpiData")
    );

    const finalObj = {};

    if (baseData !== null && baseData !== undefined) {
      finalObj.basicSectorSpecificKPI = baseData;
    }

    if (socialData !== null && socialData !== undefined) {
      finalObj.socialKPI = socialData;
    }

    if (environmentalData !== null && environmentalData !== undefined) {
      finalObj.environmentKPI = environmentalData;
    }

    if (governanceData !== null && governanceData !== undefined) {
      finalObj.governanceKPI = governanceData;
    }

    const keysRemoved = removeKeys(finalObj, ["id", "score", "unit"]);
    console.log(keysRemoved);

    console.log(filterEmptyObjects(keysRemoved));

    await saveProgress(filterEmptyObjects(keysRemoved))
      .then((res) => {
        alert(res?.data?.message);
      })
      .catch((err) => {
        alert(err?.message);
      });

    // if (saveStatus === 200) {
    //   alert("Progress saved");
    // } else {
    //   alert("Error occured");
    // }
  };

  const handleReportGeneration = async () => {
    try {
      const res = await generateReport({}); // Wait for the API call to complete
      if (res) {
        // Only navigate if the response is successful
        navigate(`/score-card/${params.id}`);
        console.log(res); // Log the response if needed
      }
    } catch (err) {
      alert("Please fill all the data points before generating the report");
      console.log(err); // Log the error if the API call fails
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch users once when component mounts
  }, []);

  return (
    <>
      <div className="add-data-points_container">
        {/* HEADING DIV */}
        {/* <div className="add-data-points_heading">
          <img
            style={{ cursor: "pointer" }}
            src={back}
            alt="Back"
            height={16}
            width={16}
            onClick={() => navigate("/")}
          />
          <h2>Add essential Data points</h2>
        </div> */}
        <div className="add-data-points_heading_container">
          <PageHeading
            onClick={() => navigate("/")}
            text={"Add essential data points"}
          />
        </div>

        {/* ALL TABS DIV  */}
        <div className="add-data-points_tabs_container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "14px",
              flexWrap: "wrap",
            }}
          >
            {tabs.map((item) => (
              <Tab
                key={item.id}
                name={item.name}
                onClick={() => setCurrentTab(item.id)}
                isActive={item.id === currentTab} // Ensure this comparison is correct
              />
            ))}
          </div>

          {/* <button style={{ cursor: "pointer" }}>
            <img src={ai} alt="AI" />{" "}
            <span style={{ fontSize: "12px" }}>Upload data with AI</span>
          </button> */}
        </div>

        <div className="add-data-points_divider"></div>

        {/* RENDER ACTIVE TAB COMPONENT */}
        <div className="add-data-points_content">
          <div className="add-data-points_table_container">
            {tabs.find((tab) => tab.id === currentTab)?.component}
          </div>
        </div>
      </div>
      <div className="kpi-table-btns_div">
        {currentTab !== 0 && currentTab !== tabs.length - 1 && (
          <button
            className="kpi-table-prev-btn"
            onClick={() => setCurrentTab((prev) => prev - 1)}
          >
            Previous
          </button>
        )}

        {currentTab !== tabs.length - 1 && (
          <button
            className="kpi-table-next-btn"
            onClick={() => setCurrentTab((prev) => prev + 1)}
          >
            Next
          </button>
        )}

        {currentTab === tabs.length - 1 && (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {/* <OutlinedButton type="button" onClick={handleSaveProgress}>
              Save Progress
            </OutlinedButton> */}
            <GradientButton
              disable={generateLoading}
              onClick={() => {
                handleReportGeneration();
              }}
            >
              {generateLoading ? `Generating` : `Generate Report`}
            </GradientButton>
          </div>
        )}
      </div>
    </>
  );
};

export default AddDataPoints;
