import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  DialogContent,
  Button,
  Dialog,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import pic from "../../assets/gesh.png";
import VideoPlayer from "../common/VideoPlayer";
import { savepassword } from "../../api/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OnboardingCompleteSVG from "../../assets/Onboarding.svg";
import { textFieldStyles } from "../../styles/inputStyles";
import TermsModal from "../Authentication/TermsModal";
import api from "../../api";
import { teamEndpoints } from "../../api/endpoints/team.endpoints";
import { useAuth } from "../context/AuthContext";

const styles = {
  fontSize: "13px",
  // borderColor: "#E0E0E0",
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    borderColor: "#E0E0E0",
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: "#C5D2CB", // Conditional border color
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Inter",
  },
  "& .MuiFormHelperText-root": {
    color: "red", // Custom helper text color
  },
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    borderColor: "#EEEEEE",
    maxHeight: "40px",
    fontSize: "14px",
    padding: "11.5px 12px", // Adjust the padding to reduce the size
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontSize: "13px",
    transform: "translate(12px, 12px) scale(1)", // Adjust the label position
    color: "#787878",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(15px, -7px) scale(0.8)", // Adjust the label position when it shrinks
  },
};

const TeamMembersRegister = () => {
  const location = useLocation();
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState({
    email: false,
    password: false,
  });
  const [helperText, setHelperText] = useState({
    email: "",
    password: "",
  });
  const [agreed, setAgreed] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    setEmail(location.state);
  }, [location]);

  useEffect(() => {
    const isEmailValid = email?.trim() !== "" && validateEmail(email);
    const isPasswordValid = password?.trim() !== "";
    const isAgreedValid = agreed;
    setIsFormValid(isEmailValid && isPasswordValid && isAgreedValid);
  }, [email, password, agreed]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmailValid = email.trim() !== "" && validateEmail(email);
    const isPasswordValid = password.trim() !== "";
    const isAgreedValid = agreed;

    setError({
      email: !isEmailValid,
      password: !isPasswordValid,
    });
    setHelperText({
      email: !isEmailValid ? "Please enter a valid email address" : "",
      password: !isPasswordValid ? "Password is required" : "",
    });

    const formData = new FormData();
    formData.append("user_password", password);

    try {
      if (isEmailValid && isPasswordValid && isAgreedValid) {
        let response = await api.post(teamEndpoints.registerStep2, formData);
        console.log("pass", response);
        if (response.status === 200) {
          setIsAuthenticated(true);
          setTimeout(() => {
            navigate("/team");
          }, 100); // 100 ms delay
        } else {
          console.log("Could not update password");
        }
      }
    } catch (error) {
      console.log(error);
    }

    try {
      setShowSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("memberEmail")) {
      setEmail(localStorage.getItem("memberEmail"));
    }
  }, []);

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} md={6} style={{ height: "100%", width: "100%" }}>
        <VideoPlayer />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box style={{ maxWidth: "400px", padding: "0 20px" }}>
          <img
            src={pic}
            alt="Logo"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <Typography
            variant="h1"
            gutterBottom
            style={{ fontFamily: "Inter", fontSize: "36px", fontWeight: 700 }}
          >
            Register Account!
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: "16px",
              marginBottom: "3rem",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "36",
              color: "#8692A6",
            }}
          >
            Join us to access sustainability reports and track your progress
            towards a greener future.
          </Typography>

          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FormControl
              sx={{
                width: "100%",
                mb: "20px",
              }}
            >
              <TextField
                disabled
                sx={textFieldStyles}
                id="email"
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error.email}
                helperText={helperText.email}
              />
            </FormControl>

            <FormControl sx={{ mb: 3 }} fullWidth variant="outlined">
              <InputLabel
                sx={{
                  fontSize: "13px",
                  color: "#666666", // Default color
                  "&.Mui-focused": {
                    color: "#666666", // Keep the color the same when focused
                  },
                  fontWeight: 500,
                }}
                htmlFor="outlined-adornment-password"
              >
                Password
              </InputLabel>
              <OutlinedInput
                sx={textFieldStyles}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {helperText.password && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ mt: "5px", fontSize: "12px", color: "#F51934" }}
                >
                  {helperText.password}
                </Typography>
              )}
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  sx={{
                    color: "primary",
                    "&.Mui-checked": {
                      color: "#43BAB9",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#696F79",
                  }}
                >
                  I agree to{" "}
                  <span
                    onClick={() => {
                      setIsTermsOpen(true);
                    }}
                    style={{
                      background:
                        "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                    }}
                  >
                    terms & conditions
                  </span>
                </Typography>
              }
              sx={{
                marginTop: "2rem",
                fontFamily: "Inter",
                fontWeight: 400,
                "& .MuiFormControlLabel-label": {
                  fontFamily: "Inter, Arial, sans-serif",
                },
              }}
            />
            <button
              type="submit"
              disabled={!isFormValid}
              className="signup_verify_btn"
            >
              Register Account
            </button>
          </form>
        </Box>
      </Grid>

      <TermsModal
        isTermsOpen={isTermsOpen}
        onClose={() => setIsTermsOpen(false)}
        onCancel={() => setAgreed(false)}
        onAgree={() => setAgreed(true)}
      />
    </Grid>
  );
};

export default TeamMembersRegister;
