/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { animated, useTransition, to } from "@react-spring/web";
import { LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";

// Accessor function for the value
const value = (d) => d.value;

const defaultMargin = { top: 30, right: 30, bottom: 15, left: 30 };

export default function LevelDoughnutChart({
  valueProp = 0, // The actual value you want to display
  maxValue = 100, // The maximum value for the doughnut
  colors = ["#00f", "#ff0", "#fff"], // First two colors are dynamic, third is white for remaining part
  width,
  height,
  margin = defaultMargin,
  animate = true,
  centerText = "", // New prop for center text
  subText = "subtext", // Subtext
  subTextMaxLength = 20, // Set the maximum length of subText per line
  thresholds = { low: 30, medium: 50, high: 70 },
  legend = [],
  unit = "", // Optional unit
}) {
  // Tooltip logic
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
    tooltipData,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const innerRadius = radius * 0.8;

  // Calculate remaining value
  const remainingValue = maxValue - valueProp;

  // Determine color based on the value compared to the threshold
  const getColor = (value) => {
    if (value < thresholds.low) return colors[0]; // Low value color
    if (value < thresholds.medium) return colors[1]; // Medium value color
    return colors[2]; // High value color
  };

  // Create the data for the pie chart
  const data = [
    { label: "Value", value: valueProp, color: getColor(valueProp) },
    { label: "Remaining", value: remainingValue, color: "#fff" },
  ];

  // Split the subtext into multiple lines if it's too long
  const subTextLines = subText
    ? subText.match(new RegExp(`.{1,${subTextMaxLength}}`, "g")) || [subText]
    : [];

  const colorScale = scaleOrdinal({
    domain: legend.map((item) => item.label),
    range: legend.map((item) => item.color),
  });

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      ref={containerRef}
    >
      <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          {/* Text in the middle of the doughnut */}
          <text
            x={0}
            y={-10}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            {centerText}
          </text>

          {/* Subtext */}
          {subTextLines.map((line, index) => (
            <text
              key={index}
              x={0}
              y={10 + index * 11} // Adjust the Y position for each line of subText
              textAnchor="middle"
              dominantBaseline="middle"
              style={{ fontSize: 10, fontWeight: "500" }}
            >
              {line}
            </text>
          ))}

          <Pie
            data={data}
            pieValue={value}
            outerRadius={radius} // Use the full radius to fill the chart
            innerRadius={innerRadius} // Set inner radius to create a doughnut chart
            pieSort={(a, b) => a.index - b.index}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                animate={animate}
                getKey={(arc) => arc.data.label}
                getColor={(arc) => arc.data.color} // Use the custom color defined in data
                getPercentage={(arc) =>
                  ((arc.data.value / maxValue) * 100).toFixed(2)
                }
                onHoverDatum={(arc, event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipTop: eventSvgCoords.y,
                    tooltipLeft: eventSvgCoords.x,
                    tooltipData: {
                      ...arc.data,
                      percentage: ((arc.data.value / maxValue) * 100).toFixed(
                        2
                      ),
                    },
                  });
                }}
                onLeaveDatum={hideTooltip}
              />
            )}
          </Pie>
        </Group>
      </svg>

      {/* Tooltip */}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            backgroundColor: "white",
            padding: 12,
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            borderRadius: "4px",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              style={{
                backgroundColor:
                  tooltipData.label === "Value"
                    ? getColor(tooltipData.value)
                    : "#fff",
                height: 15,
                width: 15,
              }}
            ></div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div>
                <span style={{ color: "#BDBDBD" }}>{tooltipData.label}</span>
              </div>
              <div
                style={{
                  height: "3px",
                  width: "3px",
                  borderRadius: "100%",
                  backgroundColor: "#BDBDBD",
                }}
              ></div>
              <div style={{ color: "#717171" }}>{tooltipData.percentage}%</div>
              <div
                style={{
                  height: "3px",
                  width: "3px",
                  borderRadius: "100%",
                  backgroundColor: "#BDBDBD",
                }}
              ></div>
              <div>
                {tooltipData.value} {unit}
              </div>
            </div>
          </div>
        </TooltipInPortal>
      )}

      {legend.length > 0 && (
        <div style={{ marginTop: 5, width: "100%" }}>
          <LegendOrdinal
            scale={colorScale}
            labelFormat={(label) => label}
            direction="row"
            itemMargin="5px"
            shape="circle"
            shapeHeight={10}
            shapeWidth={10}
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "0.5rem",
              flexWrap: "wrap",
            }}
          />
        </div>
      )}
    </div>
  );
}

// react-spring transition definitions
const fromLeaveTransition = ({ endAngle }) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  getPercentage,
  onHoverDatum,
  onLeaveDatum,
}) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => {
    return (
      <g
        key={key}
        onMouseEnter={(event) => onHoverDatum(arc, event)}
        onMouseMove={(event) => onHoverDatum(arc, event)}
        onMouseLeave={onLeaveDatum}
      >
        <animated.path
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
          stroke="#dbdbdb"
          strokeWidth={"1px"}
        />
      </g>
    );
  });
}
