import React, { useState, useEffect } from "react";
import { useTaggingStore } from "./chatStore";
import topUser from "../../../../assets/images/topUser.png";
import X from "../../../../assets/blackX.svg";
import AvatarGroup from "./AvatarGroup";
import constant from "../../../../constant";
import { useParams } from "react-router-dom";
import usePutApi from "../../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../../api/endpoints/report.endpoints";
import { useAuth } from "../../../context/AuthContext";

const UserTagging = ({ code, kpiName, alreadyTagged }) => {
  const [inputValue, setInputValue] = useState("");
  const [triggeredAt, setTriggeredAt] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const params = useParams();

  const { user } = useAuth();
  const isAdmin = user?.role === "admin";

  const { data: assignRes, put: assignUserApi } = usePutApi(
    reportEndpoints.assignUser(params.id)
  );

  // Zustand store actions and state
  const {
    taggedUsers,
    suggestions,
    allUsers,
    fetchUsers,
    addUser,
    removeUser,
    setSuggestions,
    initializeTaggedUsers,
  } = useTaggingStore();

  const [loaded, setLoaded] = useState(false); // Track if tagged users are initialized

  useEffect(() => {
    if (allUsers?.length && !loaded) {
      // Map alreadyTagged IDs to corresponding user objects
      const mappedTaggedUsers = alreadyTagged
        ?.map((id) => allUsers?.find((user) => user?.id === id))
        ?.filter(Boolean);

      initializeTaggedUsers(mappedTaggedUsers); // Initialize tagged users with loaded users
      setLoaded(true); // Ensure initialization only happens once
    }
  }, [alreadyTagged, allUsers, initializeTaggedUsers, loaded]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (showUserList) setShowUserList(false);

    const lastWord = value.split(" ").pop();
    if (lastWord.startsWith("@")) {
      const query = lastWord.slice(1).toLowerCase();

      const filteredUsers = allUsers?.filter(
        (user) =>
          user?.name?.toLowerCase()?.includes(query) &&
          !taggedUsers.some((tagged) => tagged.id === user.id)
      );
      setSuggestions(filteredUsers);
      setTriggeredAt(true);
    } else {
      setTriggeredAt(false);
      setSuggestions([]);
    }
  };

  const handleUserSelect = async (user) => {
    const updatedTaggedIds = [...new Set([...alreadyTagged, user.id])];

    try {
      await assignUserApi({
        assign: updatedTaggedIds?.map((id) => ({ userId: id })),
        code: code,
        kpiName: kpiName,
      });

      addUser(user);
      alreadyTagged.push(user.id);
    } catch (err) {
      console.log("Error assigning user:", err);
    }

    const words = inputValue.split(" ");
    words.pop();
    setInputValue(words.join(" ") + " ");
    setSuggestions([]);
    setTriggeredAt(false);
    setInputValue("");
  };

  const handleDeleteCapsule = async (id) => {
    // Update the alreadyTagged array to exclude the deleted user ID
    const updatedTaggedIds = alreadyTagged.filter(
      (taggedId) => taggedId !== id
    );

    // Make the PUT API call with the updated user ID list
    try {
      await assignUserApi({
        assign: updatedTaggedIds?.map((userId) => ({ userId })), // Format IDs for API
        code: code,
        kpiName: kpiName,
      });
      // Remove the user from the tagged users list in Zustand
      removeUser(id);

      // Update the alreadyTagged array locally
      const index = alreadyTagged.indexOf(id);
      if (index > -1) {
        alreadyTagged.splice(index, 1);
      }
    } catch (err) {
      console.log("Error removing user:", err);
    }
  };

  const toggleUserList = () => {
    setShowUserList(!showUserList);
    setTriggeredAt(false);
  };

  return (
    <div>
      {/* Capsule and Input Container */}
      <div
        className="input-capsule_container"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* Display first capsule */}
        {taggedUsers?.length > 0 && (
          <div key={`tagged-${taggedUsers[0]?.id}`} className="user_capsule">
            <div className="user_capsule-details">
              <img
                src={
                  taggedUsers[0].profileImage
                    ? `${constant.IMG_URL}/${taggedUsers[0].profileImage}`
                    : topUser
                }
                height={30}
                width={30}
                alt="User"
                style={{ borderRadius: "50%" }}
              />
              <span>{taggedUsers[0].name}</span>
            </div>
            {isAdmin ? (
              <img
                src={X}
                height={20}
                width={20}
                alt="Remove"
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  borderRadius: "50%",
                }}
                onClick={() => handleDeleteCapsule(taggedUsers[0].id)}
              />
            ) : null}
          </div>
        )}

        {/* Display Avatar Group if there are more than one capsule */}
        {taggedUsers?.length > 1 && (
          <AvatarGroup
            users={taggedUsers.slice(1)}
            maxDisplay={3}
            onClick={toggleUserList}
          />
        )}

        {/* Input Field */}
        <input
          disabled={!isAdmin}
          className="user_tagging-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="@Tag Member"
          style={{
            border: "none",
            outline: "none",
            flex: "1",
            minWidth: "150px",
          }}
        />
      </div>

      {/* Suggestions List */}
      {triggeredAt && suggestions?.length > 0 && !showUserList && (
        <ul className="user_tagging-suggestions">
          {suggestions?.map((user) => (
            <li
              className="user-option"
              key={`suggested-${user.id}`}
              onClick={() => handleUserSelect(user)}
              style={{
                cursor: "pointer",
                padding: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {isAdmin ? (
                <img
                  src={`${constant.IMG_URL}/${user.profileImage}`}
                  alt={user.name}
                  height={30}
                  width={30}
                  style={{ borderRadius: "50%" }}
                />
              ) : null}
              <span>{user.name}</span>
            </li>
          ))}
        </ul>
      )}

      {/* Full list of tagged users (shown on clicking the AvatarGroup) */}
      {showUserList && (
        <div className="tagged_users-list" style={{ marginTop: "10px" }}>
          {taggedUsers?.map((user) => (
            <div
              key={`list-${user.id}`}
              className="user_capsule"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                marginBottom: "5px",
              }}
            >
              <img
                src={`${constant.IMG_URL}/${user.profileImage}`}
                height={30}
                width={30}
                alt="User"
                style={{ borderRadius: "50%" }}
              />
              <span>{user.name}</span>
              {isAdmin ? (
                <img
                  src={X}
                  height={15}
                  width={15}
                  alt="Remove"
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                  onClick={() => handleDeleteCapsule(user.id)}
                />
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserTagging;
