import React from "react";
import "./rejected.css";

const AssignButton = ({ text = "Assign", ...props }) => {
  return (
    <button className="assign-btn" {...props}>
      {text}
    </button>
  );
};

export default AssignButton;
