import React from "react";
import useFetch from "../../../utils/hooks/useFetch";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { Link } from "react-router-dom";
import { convertDateToDDMMYYYY } from "../../../utils/dateFunctions";

const SaveAsReady = ({ search }) => {
  const {
    data: readyReports,
    loading: loadingReadyReports,
    error: readyReportsErr,
  } = useFetch(reportEndpoints.getReports, {
    status: "ready",
  });

  const rows = [
    {
      id: 0,
      reportName: "Report 1",
      submittedDate: "05-05-2024",
      status: "Verified",
    },
    {
      id: 1,
      reportName: "Report 2",
      submittedDate: "10-05-2024",
      status: "Not Verified",
    },
  ];

  const headings = ["Report Name", "Report Generated On", "Status", "Actions"];

  const filteredReports = readyReports?.reports?.filter((item) =>
    item?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  return (
    <div className="reports-table-container">
      <div className="reports-table">
        <div className="reports-table-row reports-table-header">
          {headings.map((item) => (
            <div key={item} className="reports-table-cell">
              {item}
            </div>
          ))}
        </div>
        {filteredReports?.map((item) => {
          return (
            <div key={item?.id} className="reports-table-row">
              <div className="reports-table-cell">{item?.name}</div>
              <div className="reports-table-cell">
                {convertDateToDDMMYYYY(item?.createdAt)}
              </div>
              <div
                style={{ textTransform: "capitalize" }}
                className={`reports-table-cell ${
                  item.status !== "Verified"
                    ? "report-unverified"
                    : "report-verified"
                }`}
              >
                {item.status}
              </div>
              <div className="reports-table-cell">
                <Link to={`/score-card/${item?.id}`} target="_blank">
                  <button className="report-actions-btn">Preview</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SaveAsReady;
