import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import "./teamMembers.css";
import trash from "../../../assets/trash.svg";
import useFetch from "../../../utils/hooks/useFetch";
import { teamEndpoints } from "../../../api/endpoints/team.endpoints";
import usePutApi from "../../../utils/hooks/usePutApi";
import api from "../../../api";

const AllMembers = ({ searchQuery }) => {
  const [selectedValues, setSelectedValues] = useState({});
  const { data: invites } = useFetch(teamEndpoints.getAllMembers, {
    status: "accepted",
  });

  // Set initial selected values when invites data is loaded
  useEffect(() => {
    if (invites?.data) {
      const initialValues = invites.data.reduce((acc, item) => {
        acc[item.id] = item.role === "admin" ? "owner" : item.role || "viewer";
        return acc;
      }, {});
      setSelectedValues(initialValues);
    }
  }, [invites]);

  const heading = ["Member Name", "Email", "Last Active On", "User Type"];

  const handleChange = (id, event) => {
    setSelectedValues((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const renderSelectedValue = (value) => {
    switch (value) {
      case "viewer":
        return "Can View";
      case "member":
        return "Can Edit";
      case "remove":
        return "Removed";
      default:
        return "Can View";
    }
  };

  const onRoleChange = async (id, e) => {
    const res = await api.put(teamEndpoints.editRole(id), {
      role: e.target.value,
    });
  };

  // Filter invites data based on the search query
  const filteredInvites = invites?.data?.filter(
    (item) =>
      item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      item?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <div className="table-container">
      <div className="table">
        <div className="table-row table-header">
          {heading.map((item) => (
            <div key={item} className="table-cell">
              {item}
            </div>
          ))}
        </div>

        {filteredInvites?.map((item) => (
          <div key={item?.id} className="table-row">
            <div className="table-cell">{item.name || "Dummy Name"}</div>
            <div className="table-cell">{item?.email}</div>
            <div className="table-cell">
              {moment(item?.lastActive).format("DD/MM/YYYY")}
            </div>
            <div className="table-cell">
              {item.role === "admin" ? (
                <span>Owner</span>
              ) : (
                <FormControl
                  sx={{
                    maxWidth: "95px",
                    maxHeight: "38px",
                  }}
                  fullWidth
                >
                  <Select
                    value={selectedValues[item.id] || "viewer"}
                    onChange={(event) => {
                      handleChange(item.id, event);
                      onRoleChange(item.id, event);
                    }}
                    renderValue={(value) => renderSelectedValue(value)}
                    sx={{
                      borderRadius: "8px",
                      borderColor: "#D4D4D4",
                      height: "32px",
                      fontSize: {
                        lg: "12px",
                      },
                      "& .MuiSelect-select": {
                        padding: "8px 14px",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiSvgIcon-root": {
                        right: "8px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "8px",
                          fontSize: "12px",
                        },
                      },
                    }}
                  >
                    <MenuItem value="viewer" sx={{ fontSize: "12px" }}>
                      <ListItemIcon>
                        {selectedValues[item.id] === "viewer" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can View" />
                    </MenuItem>
                    <MenuItem value="member" sx={{ fontSize: "12px" }}>
                      <ListItemIcon>
                        {selectedValues[item.id] === "member" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can Edit" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      value="remove"
                      sx={{ color: "red", fontSize: "12px" }}
                    >
                      <ListItemIcon>
                        <img src={trash} alt="Delete" height={15} width={15} />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllMembers;
