import React from "react";
import "./dashboardStyles.css";
import threeDots from "../../assets/threeDots.svg";
import { Link } from "react-router-dom";
import { convertDateToDDMMYYYY } from "../../utils/dateFunctions";

const ReportForVerification = (report) => {
  console.log(report);
  const { id, name, year, segment, organizationDetails, grade, score } = report;
  return (
    <Link
      target="_blank"
      to={`/score-card/${id}`}
      style={{
        textDecoration: "none",
        color: "black",
        display: "inline-block",
      }}
    >
      <div className="verification_card">
        <div className="verification_card-heading_container">
          <div>
            <h3 className="verification_card-heading">
              {/* {organizationDetails?.organization?.name} {segment} {year} */}
              {report?.name}
            </h3>
          </div>
          <div className="verification_card-status_container">
            <div
              className="verification_card-status_div"
              style={{ backgroundColor: "#FFCBC4" }}
            >
              Not Verified
            </div>

            <img
              style={{ cursor: "pointer" }}
              src={threeDots}
              height={20}
              width={20}
              alt="Actions"
            />
          </div>
        </div>

        <div className="verification_card-progress_container">
          <span className="verification_card-percent">
            {/* 65% */}
            {score?.toFixed(2) ?? 65}%
          </span>
          <span className="verification_card-percent-name">
            Sustainability Index Score Card
          </span>
        </div>

        <div className="verification_card-grading_container">
          <span className="verification_card-grade">
            {grade ?? "BC"} Overall Grade
          </span>
          <span className="verification_card-grade_date">
            {convertDateToDDMMYYYY(report?.updatedAt)}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default ReportForVerification;
