import constant from "../../../../constant";
import topUser from "../../../../assets/images/topUser.png";

const AvatarGroup = ({ users, maxDisplay = 3, onClick }) => {
  const displayedUsers = users?.slice(0, maxDisplay);
  const extraUsersCount = users?.length - maxDisplay;

  return (
    <div onClick={onClick} style={{ display: "flex", alignItems: "center" }}>
      {/* Display avatars with reversed zIndex for overlapping effect */}
      {displayedUsers?.map((user, index) => (
        <div
          key={user.id}
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            overflow: "hidden",
            border: "2px solid white", // Optional: white border around avatar
            marginLeft: index > 0 ? "-10px" : "0", // Overlapping avatars
            zIndex: index, // Reverse zIndex so the first avatar is on top
          }}
        >
          <img
            src={`${constant.IMG_URL}/${user.profileImage}` || topUser}
            alt={user.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      ))}

      {/* Show "+N" for extra users, but it should appear underneath */}
      {extraUsersCount > 0 && (
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#FF3A3A",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontWeight: 500,
            marginLeft: "-10px",
            //   zIndex: -1, // Ensure it stays underneath all avatars
            zIndex: 2, // Ensure it stays underneath all avatars
            fontSize: "0.625rem",
            border: "2px solid white", // Optional: white border around the extra user count
          }}
        >
          +{extraUsersCount}
        </div>
      )}
    </div>
  );
};

export default AvatarGroup;
