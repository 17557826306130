import React, { useState, useRef } from "react";
import { Dialog } from "@mui/material"; // Assuming you're using Material UI for modals
import "./uploadModal.css"; // Add styles for modal
import uploadDocs from "../../../../assets/icons/uploadDocs.svg"; // Import the uploadDocs icon
import OutlinedButton from "../../../common/OutlinedButton";
import GradientButton from "../../../common/GradientButton";
import xls from "../../../../assets/icons/xls.svg";
import csv from "../../../../assets/icons/csv.svg";
import pdf from "../../../../assets/icons/pdf.svg";
import remove from "../../../../assets/icons/remove.svg";

const UploadModal = ({ isOpen, onClose, onFileSelect }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null); // Reference to the hidden input

  // Handle file selection via drag-and-drop or input
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files || e.dataTransfer.files);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  // Handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    handleFileSelect(e);
  };

  // Trigger file input when the Upload button is clicked
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // Handle the upload of the selected files
  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      onFileSelect(selectedFiles); // Pass the files to the parent
      setSelectedFiles([]); // Clear after upload
      onClose(); // Close the modal after upload
    }
  };

  // Remove a specific file from the list by index
  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase(); // Get file extension
    switch (fileExtension) {
      case "pdf":
        return <img src={pdf} height={30} width={30} alt="Pdf" />;
      case "xls":
      case "xlsx":
        return <img src={xls} height={30} width={30} alt="Excel" />;
      case "csv":
        return <img src={csv} height={30} width={30} alt="CSV" />;
      default:
        return <span>Unknown</span>; // Fallback for unknown file types
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="upload-dialog"
      PaperProps={{
        style: {
          maxWidth: "46rem",
          width: "100%",
          padding: "2.5rem",
          maxHeight: "28.25rem",
          position: "relative", // Position for close button
        },
      }}
    >
      <div className="upload_docs-modal">
        {/* Close button */}
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <img src={uploadDocs} height={23} width={18} alt="Docs" />
          <h2>Upload Documents</h2>
        </div>

        {/* Drop zone */}
        <div
          className="drop-zone"
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          {selectedFiles.length === 0 ? (
            <div className="drop-zone-content">
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <img src={xls} height={32} width={32} alt="" />
                <img src={csv} height={32} width={32} alt="" />
                <img src={pdf} height={32} width={32} alt="" />
              </div>

              <p>
                Click upload
                <br />
                or drag and drop your PDF, CSV/Excel files
              </p>
            </div>
          ) : (
            <div className="file-preview-container">
              {selectedFiles.map((file, index) => (
                <div key={index} className="file-preview">
                  <div className="file-preview-img">
                    {getFileIcon(file.name)}
                  </div>
                  <div className="file-preview_name">
                    {file.name.length > 16
                      ? `${file.name.slice(0, 13)}...`
                      : file.name}
                  </div>

                  <img
                    className="remove-file"
                    src={remove}
                    height={15}
                    width={15}
                    alt="Remove"
                    onClick={() => removeFile(index)} // Call removeFile on click
                    style={{ cursor: "pointer" }} // Add cursor pointer for better UX
                  />
                </div>
              ))}
            </div>
          )}
          <input
            type="file"
            multiple
            onChange={handleFileSelect}
            style={{ display: "none" }}
            id="file-input"
            ref={fileInputRef} // Attach the ref to the input
          />
        </div>

        {/* Buttons section */}
        <div className="outside-upload-actions">
          {selectedFiles.length === 0 ? (
            <OutlinedButton
              height={"3.5rem"}
              width={"14rem"}
              onClick={triggerFileInput}
            >
              Upload
            </OutlinedButton> // Single button for selecting files
          ) : (
            <div className="upload-and-send">
              {/* Show Upload and Send buttons once files are selected */}
              <OutlinedButton onClick={triggerFileInput}>Upload</OutlinedButton>
              <GradientButton onClick={handleUpload}>Send</GradientButton>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UploadModal;
