import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Menu,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import threeDots from "../../../assets/threeDots.svg";
import trash from "../../../assets/trash.svg";
import CheckIcon from "@mui/icons-material/Check";
import { teamEndpoints } from "../../../api/endpoints/team.endpoints";
import useFetch from "../../../utils/hooks/useFetch";
import moment from "moment";
import api from "../../../api";
import EditModal from "./EditModal"; // Import the EditModal component

const PendingMembers = ({ searchQuery }) => {
  const [selectedValues, setSelectedValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { data: invites, refetch } = useFetch(teamEndpoints.getAllMembers, {
    status: "pending",
  });

  const heading = ["Email", "Invite Date", "User Type", ""];

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleChange = (id, event) => {
    const newRole = event.target.value;
    setSelectedValues((prev) => ({
      ...prev,
      [id]: newRole,
    }));

    if (newRole === "remove") {
      handleDeleteRequest(id);
    } else {
      handleRoleChange(id, newRole);
    }
  };

  const renderSelectedValue = (value) => {
    switch (value) {
      case "viewer":
        return "Can View";
      case "member":
        return "Can Edit";
      case "remove":
        return "Removed";
      default:
        return "Can View";
    }
  };

  const filteredInvites = invites?.data?.filter(
    (item) =>
      item?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      moment(item?.invited?.invitedDate)
        .format("DD/MM/YYYY")
        .includes(searchQuery)
  );

  const handleRoleChange = async (id, role) => {
    try {
      await api.put(teamEndpoints.editRole(id), { role });
      refetch();
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      await api.delete(teamEndpoints.deletePendingReq(id));
      refetch();
      handleClose(); // Close menu after deleting
    } catch (error) {
      console.error("Error deleting request:", error);
    }
  };

  return (
    <div className="table-container">
      <div className="table">
        <div className="table-row table-header">
          {heading.map((item) => (
            <div
              key={item}
              className="table-cell"
              style={{ textAlign: item === "User Type" ? "center" : "left" }}
            >
              {item}
            </div>
          ))}
        </div>

        {filteredInvites?.map((item) => (
          <div key={item.id} className="table-row">
            <div className="table-cell">{item?.email}</div>
            <div className="table-cell">
              {moment(item?.invited?.invitedDate).format("DD/MM/YYYY")}
            </div>
            <div className="table-cell" style={{ textAlign: "center" }}>
              {item.role === "owner" ? (
                <span>Owner</span>
              ) : (
                <FormControl
                  sx={{
                    maxWidth: "95px",
                    maxHeight: "38px",
                  }}
                  fullWidth
                >
                  <Select
                    value={item.role || "viewer"}
                    onChange={(event) => handleChange(item.id, event)}
                    renderValue={(value) => renderSelectedValue(value)}
                    displayEmpty
                    sx={{
                      borderRadius: "8px",
                      borderColor: "#D4D4D4",
                      height: "32px",
                      fontSize: {
                        lg: "12px",
                      },
                      "& .MuiSelect-select": {
                        padding: "8px 14px",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiSvgIcon-root": {
                        right: "8px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "8px",
                          fontSize: "12px",
                        },
                      },
                    }}
                  >
                    <MenuItem value="viewer" sx={{ fontSize: "12px" }}>
                      <ListItemIcon>
                        {item.role === "viewer" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can View" />
                    </MenuItem>
                    <MenuItem value="member" sx={{ fontSize: "12px" }}>
                      <ListItemIcon>
                        {selectedValues[item.id] === "member" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can Edit" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      value="remove"
                      sx={{ color: "red", fontSize: "12px" }}
                    >
                      <ListItemIcon>
                        <img src={trash} alt="Delete" height={15} width={15} />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="table-cell">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenu ? "long-menu" : undefined}
                  aria-expanded={openMenu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, item)} // Pass full row data to handleClick
                >
                  <img src={threeDots} alt="Options" height={30} width={30} />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        borderRadius: "12px",
                        border: "1px solid #8A8686CC",
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => handleDeleteRequest(item.id)} // Directly use item.id
                    sx={{ color: "red", fontSize: { lg: "12px" } }}
                  >
                    <ListItemIcon>
                      <img src={trash} alt="Remove" height={18} width={18} />
                    </ListItemIcon>
                    <ListItemText primary="Remove" />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Edit Modal */}
      {openModal && (
        <EditModal
          open={openModal}
          onClose={handleModalClose}
          data={selectedRow}
        />
      )}
    </div>
  );
};

export default PendingMembers;
