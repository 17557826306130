import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SetupOrg from "./components/Registration/SetupOrg";
import InviteTeam from "./components/Registration/InviteTeam";
import Login from "./components/Authentication/Login";
import OwnerSetUp from "./components/owner/OwnerSetUp";
import PersonalInfo from "./components/owner/PersonalInfo";
import DashBoard from "./components/Dashboard/DashBoard";
import { TeamMembers } from "./components/owner/teamMembers";
import PrivateRoute from "./components/common/PrivateRoute";
import Monitor from "./components/Dashboard/Monitor";
import SignUp from "./components/Authentication/SignUp";
import { AuthProvider } from "./components/context/AuthContext";
import CreateOrganization from "./components/Authentication/CreateOrganization";
import AddDataPoints from "./components/owner/dataPoints/AddDataPoints";
import Reports from "./components/owner/reports/Reports";
import { Subscription } from "./components/subscriptions";
import TeamMembersRegister from "./components/teamMembersLogin/TeamMembersRegister";
import TeamMembersProfile from "./components/teamMembersLogin/TeamMembersProfile";
import TeamMembersDashboard from "./components/Dashboard/TeamMembersDashboard";
import TeamMemberDataPoints from "./components/teamMembers/dataPoints/TeamMemberDataPoints";
import ResetPassword from "./components/Authentication/ResetPassword";
import ResetPasswordOTP from "./components/Authentication/ResetPasswordOTP";
import NewPassword from "./components/Authentication/NewPassword";
import SustainabilityIndex from "./components/sustainabilityIndex/SustainabilityIndex";
import TermsAndConditions from "./components/Policies/TermsAndConditions";
import { ManageAccount } from "./components/owner/manageAccount";
import { OrgContextProvider } from "./context/OrgContext";
import RejectedReport from "./components/owner/reports/rejected/RejectedReport";
import TeamMembersLogin from "./components/teamMembersLogin/TeamMembersLogin";
import TeamMembersResetPassword from "./components/teamMembersLogin/TeamMembersResetPassword";
import TeamResetPasswordOTP from "./components/teamMembersLogin/TeamResetPasswordOTP";
import TeamMembersNewPassword from "./components/teamMembersLogin/TeamMembersNewPassword";
import TeamDashboardIndex from "./components/Dashboard/SideBar/TeamDashboardIndex";
import TeamRejected from "./components/Dashboard/SideBar/TeamRejected";
import TeamRoute from "./components/context/TeamRoute";
import AdminRoute from "./components/context/AdminRoute";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/otp" element={<ResetPasswordOTP />} />
          <Route path="termsAndConditions" element={<TermsAndConditions />} />
          <Route
            path="/reset-password/new-password"
            element={<NewPassword />}
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/register" element={<CreateOrganization />} />
          <Route path="/personalinfo" element={<PersonalInfo />} />
          <Route path="/setupprofile" element={<SetupOrg />} />
          <Route path="/setup" element={<OwnerSetUp />} />

          <Route
            path="/"
            element={
              <AdminRoute>
                <OrgContextProvider>
                  <DashBoard />
                </OrgContextProvider>
              </AdminRoute>
            }
          >
            <Route index element={<Monitor />} />
            {/* <Route path="monitor" element={<Monitor />} /> */}
            <Route path="team-members" element={<TeamMembers />} />
            <Route path="add-data-points/:id" element={<AddDataPoints />} />
            <Route path="monitor" element={<Monitor />} />
            <Route path="reports" element={<Reports />} />
            <Route path="subscriptions" element={<Subscription />} />
            <Route path="manage-account" element={<ManageAccount />} />
            <Route path="rejected/:id" element={<RejectedReport />} />
          </Route>
          <Route path="/score-card/:id" element={<SustainabilityIndex />} />

          <Route path="/team-profile/:id" element={<TeamMembersProfile />} />
          <Route path="/team">
            <Route path="create-password" element={<TeamMembersRegister />} />
            <Route path="login" element={<TeamMembersLogin />} />
          </Route>

          <Route
            path="/team"
            element={
              <TeamRoute>
                <OrgContextProvider>
                  <TeamMembersDashboard />
                </OrgContextProvider>
              </TeamRoute>
            }
          >
            <Route index element={<TeamDashboardIndex />} />
            <Route path="data-points/:id" element={<TeamMemberDataPoints />} />
            <Route path="rejected/:id" element={<TeamRejected />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
