import api from ".";
export async function scheduleCalls(data) {
  try {
    const response = await api.post("/call/book", data);
    if (response) {
      return response;
    } else throw new Error("Could not book call");
  } catch (err) {
    console.log(err);
  }
}
