import { create } from "zustand";
import topUser from "../../../../assets/images/topUser.png";
import api from "../../../../api";
import { teamEndpoints } from "../../../../api/endpoints/team.endpoints";

// Dummy users data (for now)
const dummyUsers = [
  { id: 1, name: "Alice", icon: topUser },
  { id: 2, name: "Bob", icon: topUser },
  { id: 3, name: "Charlie", icon: topUser },
  { id: 4, name: "David", icon: topUser },
  { id: 5, name: "John", icon: topUser },
];

const fetchUsersApi = async () => {
  try {
    const res = await api.get(`${teamEndpoints.getAllMembers}?status=accepted`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const useTaggingStore = create((set) => ({
  taggedUsers: [],
  suggestions: [],
  allUsers: [],
  loading: false,
  error: null,
  assignMembersBySymbol: {},

  fetchUsers: async () => {
    set({ loading: true, error: null });
    try {
      const users = await fetchUsersApi();
      set({ allUsers: users?.data, suggestions: [], loading: false });
    } catch (error) {
      set({ error: "Failed to fetch users", loading: false });
    }
  },

  addUser: (user) =>
    set((state) => ({
      taggedUsers: [...state.taggedUsers, user],
    })),

  initializeTaggedUsers: (userList) =>
    set(() => ({
      taggedUsers: userList,
    })),

  removeUser: async (id) => {
    try {
      set((state) => ({
        taggedUsers: state.taggedUsers.filter((user) => user.id !== id),
      }));
    } catch (error) {
      console.error("Failed to delete user", error);
    }
  },

  setSuggestions: (suggestions) => set(() => ({ suggestions })),

  // Initialize assignMembers for a specific symbol
  initializeAssignMembers: (symbol, memberIds) =>
    set((state) => ({
      assignMembersBySymbol: {
        ...state.assignMembersBySymbol,
        [symbol]: memberIds,
      },
    })),

  // Add a member to a specific symbol's assigned members
  addAssignMember: (symbol, memberId) =>
    set((state) => ({
      assignMembersBySymbol: {
        ...state.assignMembersBySymbol,
        [symbol]: [...(state.assignMembersBySymbol[symbol] || []), memberId],
      },
    })),

  // Remove a member from a specific symbol's assigned members
  removeAssignMember: (symbol, memberId) =>
    set((state) => ({
      assignMembersBySymbol: {
        ...state.assignMembersBySymbol,
        [symbol]: state.assignMembersBySymbol[symbol]?.filter(
          (id) => id !== memberId
        ),
      },
    })),

  clearStore: () =>
    set(() => ({
      // taggedUsers: [],
      // suggestions: [],
      // allUsers: [],
      loading: false,
      error: null,
    })),
}));
