import React, { useEffect, useRef } from "react";
import introVideo from "../../assets/Video/intro.mp4";

function VideoPlayer() {
  const videoPlayer = useRef(null);

  useEffect(() => {
    const videoElement = videoPlayer.current;

    if (videoElement) {
      videoElement.play().catch((error) => {
        console.error("Error trying to play video:", error);
      });

      // Cleanup function to pause video when component unmounts
      return () => {
        videoElement.pause();
        videoElement.currentTime = 0;
      };
    }
  }, []);

  return (
    <video
      src={introVideo}
      ref={videoPlayer}
      loop
      muted
      style={{ height: "100%", width: "100%", objectFit: "cover" }}
    />
  );
}

export default VideoPlayer;
