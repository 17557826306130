import React from "react";
import useFetch from "../../../utils/hooks/useFetch";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { Link } from "react-router-dom";
import { convertDateToDDMMYYYY } from "../../../utils/dateFunctions";

const DraftsTable = ({ search }) => {
  const {
    data: draftsData,
    loading: draftsLoading,
    error: draftsError,
  } = useFetch(reportEndpoints.getReports, {
    status: "draft",
  });

  const rows = [
    {
      id: 0,
      reportName: "Report 1",
      lastEdited: "05-05-2024",
      progress: "60%",
    },
    {
      id: 1,
      reportName: "Report 2",
      lastEdited: "10-05-2024",
      status: "Not Verified",
      progress: "90%",
    },
  ];

  const headings = ["Report Name", "Last edited", "Progress", "Actions"];

  const filteredReports = draftsData?.reports?.filter((item) =>
    item?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  console.log(filteredReports);

  return (
    <div className="reports-table-container">
      <div className="reports-table">
        <div className="reports-table-row reports-table-header">
          {headings.map((item) => (
            <div key={item} className="reports-table-cell">
              {item}
            </div>
          ))}
        </div>
        {filteredReports?.map((item) => {
          const progress = (
            (item?.completedStatus?.done / item?.completedStatus?.total) *
            100
          ).toFixed(2);

          console.log(
            item?.completedStatus?.done,
            item?.completedStatus?.total
          );

          return (
            <div key={item?.id} className="reports-table-row">
              <div className="reports-table-cell">{item?.name}</div>
              <div className="reports-table-cell">
                {convertDateToDDMMYYYY(item?.lastEdited)}
              </div>
              <div className="reports-table-cell ">{progress ?? 0}%</div>
              <div className="reports-table-cell ">
                <Link to={`/add-data-points/${item?.id}`}>
                  <button className="report-actions-btn">Preview</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DraftsTable;
