import React from "react";
import "./dashboardStyles.css";
import threeDots from "../../assets/threeDots.svg";
import { Avatar, AvatarGroup } from "@mui/material";
import topUser from "../../assets/images/topUser.png";
import { convertDateToDaysAgo } from "../../utils/dateFunctions";
import { Link } from "react-router-dom";

const ReportCard = (report) => {
  let percentage =
    (
      (report?.completedStatus?.done / report?.completedStatus?.total) *
      100
    )?.toFixed(0) || 0;
  percentage = isNaN(percentage) ? 0 : percentage;
  const conicEnd = `${percentage}%`;
  const conicGradient = `conic-gradient(#369d9c 0%, #28814d ${conicEnd}, #c8ecfb ${conicEnd})`;
  const users = [1, 2, 3, 4, 5, 6]; // Example users, replace with actual data
  const maxVisible = 3;

  const editedAt = convertDateToDaysAgo(report?.updatedAt);

  console.log(percentage);

  return (
    <Link
      to={`/add-data-points/${report?.id}`}
      // target="_blank"
      style={{ textDecoration: "none", color: "black" }}
    >
      <div className="report_card">
        <div className="report_card-heading_container">
          <div className="report_card-heading-notifications">
            <h3 className="report_card-heading">{report?.name}</h3>
            <div className="report_card-notification">1</div>
          </div>

          <div>
            <img
              style={{ cursor: "pointer" }}
              src={threeDots}
              height={20}
              width={20}
              alt="Actions"
            />
          </div>
        </div>

        <div className="report_card-progress-container">
          <div
            className="progress-bar"
            style={{
              width: `42px`,
              height: `42px`,
              background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), ${conicGradient}`,
              boxShadow: "0px 3.94px 3.94px 0px #228d8c54",
            }}
          >
            <div className="progress-text">
              {isNaN(percentage) ? 0 : percentage}%
            </div>
          </div>
          <div className="report_card-status">Completed</div>
        </div>

        <div className="report_card-edit_container">
          <div>
            <span className="edited-status">
              {editedAt?.includes(":")
                ? `Edited at ${editedAt}`
                : `Edited ${editedAt}`}
            </span>
          </div>
          <div className="report_card-profile_images">
            <div className="profile-images-container">
              {users.slice(0, maxVisible).map((item, index) => (
                <img
                  key={index}
                  src={topUser}
                  alt="user"
                  className="profile-image"
                  height={24}
                  width={24}
                />
              ))}
              {users.length > maxVisible && (
                <div className="extra-count">+{users.length - maxVisible}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ReportCard;
