import React from "react";
import {
  XYChart,
  AnimatedAreaSeries,
  AnimatedLineSeries,
  AnimatedGlyphSeries,
  Tooltip,
} from "@visx/xychart";
import { LinearGradient } from "@visx/gradient";
import { Axis } from "@visx/xychart";
import { extent } from "d3-array"; // Import extent to calculate the min and max values of the data
import { curveNatural, curveMonotoneX } from "@visx/curve";
import { convertToK } from "../../utils/convertToK";

export default function MultipleLineAreaChart({
  width,
  height,
  data1,
  data2,
  tooltipLabel1 = "",
  tooltipLabel2 = "",
  unit = "",
}) {
  // Combine all quarters from both datasets and remove duplicates
  const allQuarters = [...new Set([...data1, ...data2].map((d) => d.quarter))];

  // Calculate the y-domain based on the minimum and maximum values across both datasets
  const yDomain = extent([
    ...data1.map((d) => d.value),
    ...data2.map((d) => d.value),
  ]);

  // Ensure yDomain has a minimum of 0 for better visualization
  const adjustedYDomain = [Math.min(0, yDomain[0]), yDomain[1]];

  return (
    <XYChart
      xScale={{ type: "band", domain: allQuarters }} // Set xScale to accommodate all unique quarters
      yScale={{ type: "linear", domain: adjustedYDomain, nice: true }} // Adjust yScale based on data
      height={height}
      width={width}
      margin={{ top: 40, right: 0, bottom: 30, left: 30 }} // Adjust margins as needed
    >
      {/* Define the gradients */}
      <LinearGradient id="green-gradient" from="#02b880" to="#fff" />
      <LinearGradient id="light-green-gradient" from="#e2f4ef" to="#f8f9f9" />

      {/* AnimatedAreaSeries for the filled areas */}
      <AnimatedAreaSeries
        dataKey="Area 1"
        data={data1}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        fillOpacity={0.6}
        fill="url(#green-gradient)"
        curve={curveMonotoneX} // Smooth curve
      />

      <AnimatedAreaSeries
        dataKey="Area 2"
        data={data2}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        fillOpacity={0.6}
        fill="url(#light-green-gradient)"
        curve={curveMonotoneX} // Smooth curve
      />

      {/* AnimatedLineSeries for the lines */}
      <AnimatedLineSeries
        dataKey={tooltipLabel1}
        data={data1}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        stroke="#02B880"
        strokeWidth={1.5}
        curve={curveMonotoneX} // Smooth curve
      />

      <AnimatedLineSeries
        dataKey={tooltipLabel2}
        data={data2}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        stroke="#B1E9D8"
        strokeWidth={1.5}
        curve={curveMonotoneX} // Smooth curve
      />

      {/* AnimatedGlyphSeries for the data points */}
      <AnimatedGlyphSeries
        dataKey="Points 1"
        data={data1}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        size={75}
        renderGlyph={(glyphProps) => {
          const { x, y } = glyphProps;
          return (
            <circle
              cx={x}
              cy={y}
              r={4}
              fill="#02B880"
              stroke="#fff"
              strokeWidth={1}
            />
          );
        }}
      />

      <AnimatedGlyphSeries
        dataKey="Points 2"
        data={data2}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        size={75}
        renderGlyph={(glyphProps) => {
          const { x, y } = glyphProps;
          return (
            <circle
              cx={x}
              cy={y}
              r={4}
              fill="#B1E9D8"
              stroke="#fff"
              strokeWidth={1}
            />
          );
        }}
      />

      <Axis
        orientation="bottom"
        hideAxisLine
        hideTicks
        tickLabelProps={{
          fontSize: 9,
        }}
      />
      <Axis
        orientation="left"
        hideAxisLine
        hideTicks
        numTicks={4}
        label="Electricity Consumption (in MWh)"
        labelOffset={8}
        labelProps={{
          fontSize: 9,
          textAnchor: "middle",
          // fill: "#333",
          transform: "rotate(-90)",
          fontWeight: 500,
        }}
        tickLabelProps={{
          dx: "1.8em",
          fontSize: 9,
          color: "black",
        }}
        tickFormat={convertToK}
      />
      <Tooltip
        offsetLeft={-110}
        offsetTop={5}
        renderTooltip={({ tooltipData }) => (
          <div
            style={{
              padding: "8px",
              fontWeight: 400,
              fontSize: 11,
              display: "flex",
              gap: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor:
                  tooltipData?.nearestDatum?.key === tooltipLabel1
                    ? "#02B880"
                    : "#B1E9D8",
                height: 12,
                width: 12,
              }}
            ></div>
            <div>
              {tooltipData?.nearestDatum?.key === tooltipLabel1
                ? `${tooltipLabel1} ${tooltipData?.nearestDatum?.datum.value} ${unit}`
                : `${tooltipLabel2} ${tooltipData?.nearestDatum?.datum.value} ${unit}`}
            </div>
          </div>
        )}
      />
    </XYChart>
  );
}
