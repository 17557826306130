import React, { useState, useEffect, useRef } from "react";
import "./assignments.css";
import "./chats.css";
import paperclip from "../../../../assets/icons/paperclip.svg";
import sendIcon from "../../../../assets/icons/sendIcon.svg";
import coloredSend from "../../../../assets/icons/coloredSend.svg";
import topUser from "../../../../assets/images/topUser.png";
import pdf from "../../../../assets/icons/pdf.svg";
import UploadModal from "./UploadModal"; // Import the UploadModal component

const initialMessages = [
  {
    id: 1,
    text: "Is the value of Amount spent of local suppliers correct?",
    sender: "Alice",
    time: "10:00 AM",
    me: true,
  },
  {
    id: 2,
    text: "Yes",
    sender: "Alice",
    time: "10:00 AM",
    me: false,
    icon: topUser,
  },
  {
    id: 3,
    text: "Wait, let me check",
    sender: "Bob",
    time: "10:00 AM",
    me: false,
    icon: topUser,
  },
  {
    id: 4,
    text: "I don’t find any related documents regarding it",
    sender: "",
    time: "10:00 AM",
    me: true,
  },
];

const ChatSection = () => {
  const [messages, setMessages] = useState(initialMessages);
  const [currentMessage, setCurrentMessage] = useState("");
  const [attachedFile, setAttachedFile] = useState(null); // Track file attachment
  const [uploadModalOpen, setUploadModalOpen] = useState(false); // State to manage modal
  const chatEndRef = useRef(null);

  const handleMessageInput = (e) => {
    setCurrentMessage(e.target.value);
  };

  // Function to send message and attached file (if any)
  const handleSendMessage = () => {
    if (currentMessage.trim() !== "" || attachedFile) {
      const newMessage = {
        id: messages.length + 1,
        text: currentMessage,
        sender: "Me",
        time: new Date().toLocaleTimeString(),
        me: true,
        file: attachedFile, // Attach file to the message
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setCurrentMessage("");
      setAttachedFile(null); // Clear file attachment
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Function to handle file selection from modal
  const handleFileSelect = (files) => {
    setAttachedFile(files[0]); // Only use the first file selected (for simplicity)
    setUploadModalOpen(false); // Close the modal after file selection
    handleSendMessage(); // Automatically send the message when a file is selected
  };

  return (
    <>
      <div className="chat-section">
        {/* Render each message */}
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`message-container ${msg.me ? "me" : "other"}`}
          >
            {/* If the message is from someone else, show their name with timestamp */}
            {!msg.me && (
              <div className="message-sender">
                <img src={topUser} height={22} width={22} alt="User" />
                <div className="message-sender_details">
                  <span>{msg.sender}</span>
                  <div
                    style={{
                      height: "2.3px",
                      width: "2.3px",
                      backgroundColor: "#000",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <span className="message-time">{msg.time}</span>{" "}
                </div>
              </div>
            )}

            {/* Message content */}
            <div className="message-bubble">{msg.text}</div>

            {/* Display attached file */}
            {msg.file && (
              <div
                className={`attached-file ${msg.me ? "me-file" : "other-file"}`}
                style={{
                  alignSelf: msg.me ? "flex-end" : "flex-start", // Align based on who sent the file
                }}
              >
                <img src={pdf} alt="PDF" width={32} height={32} />
                <a
                  href={URL.createObjectURL(msg.file)}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {msg.file.name}
                </a>
              </div>
            )}

            {/* For messages sent by me, show the timestamp below */}
            {msg.me && <div className="message-time">{msg.time}</div>}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      {/* Input for typing messages */}
      <div className="comment-input_container">
        <input
          type="text"
          className="comment-input"
          placeholder="Type a message..."
          onChange={handleMessageInput}
          value={currentMessage}
        />

        <div className="comment-actions">
          <button type="button" onClick={() => setUploadModalOpen(true)}>
            <img src={paperclip} height={28} width={28} alt="Attach" />
          </button>
          <button type="button" onClick={handleSendMessage}>
            {currentMessage?.trim() !== "" || attachedFile ? (
              <img src={coloredSend} height={28} width={28} alt="Send" />
            ) : (
              <img src={sendIcon} height={28} width={28} alt="Send" />
            )}
          </button>
        </div>
      </div>

      {/* Upload modal */}
      <UploadModal
        isOpen={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        onFileSelect={handleFileSelect} // Passing file select handler to modal
      />
    </>
  );
};

export default ChatSection;
