import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TeamDashboardIndex = () => {
  console.log("Index mounted");

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("memberEmail");

    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  return <div>TeamDashboardIndex</div>;
};

export default TeamDashboardIndex;
