function transformIntensityTrendData(data, selectedTrend, selectedDenominator) {
  // Map and calculate the transformed data
  const transformedData = data.map((item) => {
    // Destructure necessary fields
    const {
      segment,
      year,
      basicSectorSpecificKPI: {
        B1: {
          // scope1Emissions = {},
          // scope2Emissions = {},
          grossRevenue = {},
        } = {},
        B2: { totalWaterConsumption = {} } = {},
        B3: { totalHazardousWaste = {} } = {},
        B4: { totalNonHazardousWaste = {} } = {},
        B6: { totalElectricityConsumption = {} } = {},
      } = {},
      socialKPI: {
        S1: { totalMen = {}, totalWomen = {}, totalNonBinary = {} } = {},
      } = {},
      governanceKPI: { G10: { csrSpending = {} } = {} } = {},
      environmentKPI: {
        E1: { scope1Emissions = {}, scope2Emissions = {} } = {},
      },
      organizationDetails: { totalSiteArea = {}, unitsProduced = {} } = {},
    } = item;

    // Calculate trend value based on selectedTrend
    let trendValue = 0;
    switch (selectedTrend) {
      case "hazardousPlusNonHazardous":
        trendValue =
          (totalHazardousWaste.value || 0) +
          (totalNonHazardousWaste.value || 0);
        break;
      case "scope1PlusScope2":
        trendValue =
          (scope1Emissions.value || 0) + (scope2Emissions.value || 0);
        break;
      case "totalWaterConsumption":
        trendValue = totalWaterConsumption.value || 0;
        break;
      default:
        trendValue = 0;
    }

    // Calculate denominator value based on selectedDenominator
    let denominatorValue = 1; // Default to 1 to avoid division by 0
    switch (selectedDenominator) {
      case "totalEmployees":
        denominatorValue =
          (totalMen.value || 0) +
          (totalWomen.value || 0) +
          (totalNonBinary.value || 0);
        break;
      case "grossRevenue":
        denominatorValue = grossRevenue.value || 1;
        break;
      case "totalSiteArea":
        denominatorValue = totalSiteArea || 1;
        break;
      case "unitsProduced":
        denominatorValue = unitsProduced.value || 1;
        break;
      default:
        denominatorValue = 1;
    }

    // Validate denominator to avoid division by zero or NaN issues
    if (
      !denominatorValue ||
      isNaN(denominatorValue) ||
      denominatorValue === Infinity
    ) {
      denominatorValue = 1;
    }

    // Calculate the final value
    let value = (trendValue / denominatorValue).toFixed(2);

    // Ensure the final value is not NaN or Infinity
    if (isNaN(value) || !isFinite(value)) {
      value = 0; // Default to 0 in case of invalid calculation
    }

    // Format the quarter string
    const quarter = `${segment} ${year}`;

    // Return the formatted object
    return {
      quarter,
      value,
      segment, // Adding segment for sorting
      year, // Adding year for sorting
    };
  });

  // Function to convert quarter string to a numerical representation for sorting
  function getQuarterIndex(quarter) {
    const quarterMap = {
      Q1: 1,
      Q2: 2,
      Q3: 3,
      Q4: 4,
    };
    return quarterMap[quarter] || 0;
  }

  // Sort the transformed data by year and quarter
  transformedData.sort((a, b) => {
    const yearA = parseInt(a.year, 10);
    const yearB = parseInt(b.year, 10);

    if (yearA === yearB) {
      // Compare by quarter if the year is the same
      const quarterA = getQuarterIndex(a.segment);
      const quarterB = getQuarterIndex(b.segment);
      return quarterA - quarterB;
    }
    return yearA - yearB; // Compare by year otherwise
  });

  // Return the sorted data, excluding extra fields
  return transformedData.map(({ quarter, value }) => ({ quarter, value }));
}

const transformActionableData = (data) => {
  return data.map((item) => {
    // Ensure actionableInsights has exactly 3 items
    const actionableInsights = [...item.actionableInsights];
    while (actionableInsights.length < 3) {
      actionableInsights.push(""); // Add an empty string if less than 3 insights
    }

    return {
      name: item.name,
      code: item.code,
      ...actionableInsights.reduce((acc, insight, index) => {
        acc[`insight${index + 1}`] = insight;
        return acc;
      }, {}),
    };
  });
};

function transformDataForTrends(data) {
  // Mapping quarters to numerical values for sorting
  const quarterMap = {
    Q1: 1,
    Q2: 2,
    Q3: 3,
    Q4: 4,
  };

  return (
    data
      ?.map((item) => ({
        quarter: `${item.segment} ${item.year}`,
        value: item?.score?.toFixed(2) || 0, // Change this value based on your requirement
        year: item.year,
        quarterNumber: quarterMap[item.segment], // Map quarter to numerical value
      }))
      // Sort by year first, then by quarter number
      .sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year; // Sort by year
        }
        return a.quarterNumber - b.quarterNumber; // Sort by quarter if years are the same
      })
      // Remove the additional properties used for sorting
      .map((item) => ({
        quarter: item.quarter,
        value: item.value,
      }))
  );
}

function transformDataForGHGEmissions(data) {
  // Create a function to get the correct quarter order
  const quarterOrder = ["Q1", "Q2", "Q3", "Q4"];

  // Map and transform the input data
  return data
    .map((item) => {
      // Extract quarter and year from the item
      const quarter = item.segment;
      const year = item.year;

      // Extract the values from environmentKPI for Scope 1, Scope 2, and Scope 3
      const scope1 = item.environmentKPI?.E1?.scope1Emissions?.value || 0;
      const scope2 = item.environmentKPI?.E1?.scope2Emissions?.value || 0;
      const scope3 = item.environmentKPI?.E2?.scope3Emissions?.value || 0;

      // Return the transformed object
      return {
        quarter: `${quarter} ${year}`,
        "Scope 1": scope1,
        "Scope 2": scope2,
        "Scope 3": scope3,
      };
    })
    .sort((a, b) => {
      // Split the quarter and year and parse them
      const [quarterA, yearA] = a.quarter.split(" ");
      const [quarterB, yearB] = b.quarter.split(" ");

      // Compare the years first, then compare quarters based on their order in quarterOrder array
      if (parseInt(yearA) !== parseInt(yearB)) {
        return parseInt(yearA) - parseInt(yearB);
      }
      return quarterOrder.indexOf(quarterA) - quarterOrder.indexOf(quarterB);
    });
}

function transformDataForSoxNox(data) {
  // Create a function to get the correct quarter order
  const quarterOrder = ["Q1", "Q2", "Q3", "Q4"];

  // Map and transform the input data
  return data
    .map((item) => {
      // Extract quarter and year from the item
      const quarter = item.segment;
      const year = item.year;

      // Extract the values for SOx and NOx emissions from environmentKPI
      const sox = item.environmentKPI?.E6?.soxReleased?.value || 0;
      const nox = item.environmentKPI?.E5?.noxReleased?.value || 0;

      // Return the transformed object
      return {
        quarter: `${quarter} ${year}`,
        sox: sox,
        nox: nox,
      };
    })
    .sort((a, b) => {
      // Split the quarter and year and parse them
      const [quarterA, yearA] = a.quarter.split(" ");
      const [quarterB, yearB] = b.quarter.split(" ");

      // Compare the years first, then compare quarters based on their order in quarterOrder array
      if (parseInt(yearA) !== parseInt(yearB)) {
        return parseInt(yearA) - parseInt(yearB);
      }
      return quarterOrder.indexOf(quarterA) - quarterOrder.indexOf(quarterB);
    });
}

function transformDataForElectricity(data) {
  // Initialize the arrays for data1 and data2
  const data1 = [];
  const data2 = [];

  // Mapping for quarter sorting
  const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };

  // Loop through each item in the array
  data.forEach((item) => {
    // Destructure values from the current item
    const {
      segment,
      year,
      basicSectorSpecificKPI: {
        B6: { electricityFromRenewables = {}, electricityFromGrid = {} } = {},
      } = {},
    } = item;

    // Construct the quarter string
    const quarter = `${segment} ${year}`;

    // Check if value exists and push to data1 and data2 accordingly
    if (electricityFromRenewables.value !== undefined) {
      data1.push({
        quarter,
        value: electricityFromRenewables.value,
        year,
        quarterKey: quarterOrder[segment],
      });
    } else {
      data1.push({
        quarter,
        value: 0, // Push 0 if value is not defined
        year,
        quarterKey: quarterOrder[segment],
      });
    }

    if (electricityFromGrid.value !== undefined) {
      data2.push({
        quarter,
        value: electricityFromGrid.value,
        year,
        quarterKey: quarterOrder[segment],
      });
    } else {
      data2.push({
        quarter,
        value: 0, // Push 0 if value is not defined
        year,
        quarterKey: quarterOrder[segment],
      });
    }
  });

  // Sort data1 and data2 by year and then by quarter
  data1.sort((a, b) => a.year - b.year || a.quarterKey - b.quarterKey);
  data2.sort((a, b) => a.year - b.year || a.quarterKey - b.quarterKey);

  // Remove temporary sorting keys before returning
  const formattedData1 = data1.map(({ quarter, value }) => ({
    quarter,
    value,
  }));
  const formattedData2 = data2.map(({ quarter, value }) => ({
    quarter,
    value,
  }));

  return { data1: formattedData1, data2: formattedData2 };
}

function transformAndSortWaterConsumptionTrend(data) {
  // Mapping for quarter sorting
  const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };

  // Transform and extract data based on electricityFromRenewables.value
  const transformedData = data.map((item) => {
    const {
      segment,
      year,
      basicSectorSpecificKPI: {
        B6: { electricityFromRenewables = {} } = {},
      } = {},
    } = item;

    const quarter = `${segment} ${year}`;
    const value = electricityFromRenewables.value || 0;

    return {
      quarter,
      value,
      year,
      quarterKey: quarterOrder[segment], // For sorting purposes
    };
  });

  // Sort the transformed data based on year and quarter
  transformedData.sort(
    (a, b) => a.year - b.year || a.quarterKey - b.quarterKey
  );

  // Remove temporary sorting keys before returning
  const formattedData = transformedData.map(({ quarter, value }) => ({
    quarter,
    value,
  }));

  return formattedData;
}

function transformWasteDataForTrend(data) {
  // Mapping for quarter sorting
  const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };

  // Transform and extract data based on hazardous and non-hazardous waste values
  const transformedData = data.map((item) => {
    const {
      segment,
      year,
      basicSectorSpecificKPI: {
        B3: { totalHazardousWaste = {} } = {},
        B4: { totalNonHazardousWaste = {} } = {},
      } = {},
    } = item;

    const quarter = `${segment} ${year}`;
    const hazardous = totalHazardousWaste.value || 0;
    const nonHazardous = totalNonHazardousWaste.value || 0;

    return {
      quarter,
      year,
      quarterKey: quarterOrder[segment], // For sorting purposes
      Hazardous: hazardous,
      "Non-Hazardous": nonHazardous,
    };
  });

  // Sort the transformed data based on year and quarter
  transformedData.sort(
    (a, b) => a.year - b.year || a.quarterKey - b.quarterKey
  );

  // Remove temporary sorting keys before returning
  const formattedData = transformedData.map(
    ({ quarter, Hazardous, "Non-Hazardous": nonHazardous }) => ({
      quarter,
      Hazardous,
      "Non-Hazardous": nonHazardous,
    })
  );

  return formattedData;
}

function transformDataForWasteHorizontal(data) {
  return data
    .map((item) => {
      const {
        segment,
        year,
        environmentKPI: {
          E9: { wasteWaterTreated = {}, totalWasteWater = {} } = {},
        } = {},
      } = item;

      // Construct the quarter string
      const label = `${segment} ${year}`;

      // Calculate the percentage values (Treated / Total) * 100
      const wasteWaterTreatedValue = wasteWaterTreated.value || 0;
      const totalWasteWaterValue = totalWasteWater.value || 0;

      const treatedPercentage =
        totalWasteWaterValue > 0
          ? ((wasteWaterTreatedValue / totalWasteWaterValue) * 100).toFixed(2)
          : 0;

      const generatedPercentage = 100 - treatedPercentage;

      return {
        label,
        "Waste Water Treated": treatedPercentage,
        "Waste Water Generated": generatedPercentage,
      };
    })
    .sort((a, b) => {
      // Sort based on year and segment
      const [segmentA, yearA] = a.label.split(" ");
      const [segmentB, yearB] = b.label.split(" ");
      const segmentOrder = ["Q1", "Q2", "Q3", "Q4"];
      if (yearA !== yearB) return yearA - yearB;
      return segmentOrder.indexOf(segmentA) - segmentOrder.indexOf(segmentB);
    });
}

function transformDataForComplaints(data) {
  return data
    .map((item) => {
      const {
        segment,
        year,
        governanceKPI: {
          G8: { totalComplaints = {}, resolvedComplaints = {} } = {},
        } = {},
      } = item;

      // Construct the quarter string
      const label = `${segment} ${year}`;

      // Get values or default to 0 if undefined
      const totalComplaintsValue = totalComplaints.value || 0;
      const resolvedComplaintsValue = resolvedComplaints.value || 0;

      // Calculate resolved and unresolved complaints in percentage
      const resolvedPercentage =
        totalComplaintsValue > 0
          ? ((resolvedComplaintsValue / totalComplaintsValue) * 100).toFixed(2)
          : 0;

      const unresolvedPercentage = 100 - resolvedPercentage;

      return {
        label,
        "Complaints Resolved": resolvedPercentage,
        "Complaints Un-resolved": unresolvedPercentage,
      };
    })
    .sort((a, b) => {
      // Sort based on year and segment
      const [segmentA, yearA] = a.label.split(" ");
      const [segmentB, yearB] = b.label.split(" ");
      const segmentOrder = ["Q1", "Q2", "Q3", "Q4"];
      if (yearA !== yearB) return yearA - yearB;
      return segmentOrder.indexOf(segmentA) - segmentOrder.indexOf(segmentB);
    });
}

// SOCIAL KPI RELATED
function transformSocialTrendData(data, selectedKPIOption) {
  // Map and calculate the transformed data
  const transformedData = data.map((item) => {
    // Destructure necessary fields and default values to avoid errors
    const {
      segment,
      year,
      socialKPI: {
        S1: { totalMen = {}, totalWomen = {}, totalNonBinary = {} } = {},
        S2: { foreignEmployees = {} } = {},
        S3: { directEmployees = {}, indirectEmployees = {} } = {},
        S7: { trir = {} } = {},
      } = {},
    } = item;

    // Select the value based on the selectedKPIOption
    let kpiValue = 0;

    switch (selectedKPIOption) {
      case "totalMen":
        kpiValue = totalMen.value || 0;
        break;
      case "totalWomen":
        kpiValue = totalWomen.value || 0;
        break;
      case "totalNonBinary":
        kpiValue = totalNonBinary.value || 0;
        break;
      case "foreignEmployees":
        kpiValue = foreignEmployees.value || 0;
        break;
      case "directEmployees":
        kpiValue = directEmployees.value || 0;
        break;
      case "indirectEmployees":
        kpiValue = indirectEmployees.value || 0;
        break;
      case "trir":
        kpiValue = trir.value || 0;
        break;
      default:
        kpiValue = 0;
    }

    // Format the quarter string
    const quarter = `${segment} ${year}`;

    // Return the formatted object
    return {
      quarter,
      value: kpiValue,
      segment, // Adding segment for sorting
      year, // Adding year for sorting
    };
  });

  // Function to convert quarter string to a numerical representation for sorting
  function getQuarterIndex(quarter) {
    const quarterMap = {
      Q1: 1,
      Q2: 2,
      Q3: 3,
      Q4: 4,
    };
    return quarterMap[quarter] || 0;
  }

  // Sort the transformed data by year and quarter
  transformedData.sort((a, b) => {
    const yearA = parseInt(a.year, 10);
    const yearB = parseInt(b.year, 10);

    if (yearA === yearB) {
      // Compare by quarter if the year is the same
      const quarterA = getQuarterIndex(a.segment);
      const quarterB = getQuarterIndex(b.segment);
      return quarterA - quarterB;
    }
    return yearA - yearB; // Compare by year otherwise
  });

  // Return the sorted data, excluding extra fields
  return transformedData.map(({ quarter, value }) => ({ quarter, value }));
}

// GOVERNANCE RELATED
function transformGovernanceTrendData(data, selectedSpendingCategory) {
  // Helper function to convert quarter string into a comparable number
  const convertQuarterToNumber = (quarter) => {
    const [q, year] = quarter.split(" ");
    const quarterNumber = parseInt(q.replace("Q", ""), 10);
    return parseInt(year, 10) * 10 + quarterNumber;
  };

  // Transform the data
  const transformedData = data.map((item) => {
    const {
      segment,
      year,
      governanceKPI: {
        G3: { localSuppliersSpending = {} } = {},
        G4: { newSuppliersSpending = {} } = {},
        G5: { localCommunitiesSpending = {} } = {},
        G6: { innovativeTechnologiesSpending = {} } = {},
        G10: { csrSpending = {} } = {},
      } = {},
    } = item;

    let spendingCategoryValue = 0;
    switch (selectedSpendingCategory) {
      case "localSuppliersSpending":
        spendingCategoryValue = localSuppliersSpending.value || 0;
        break;
      case "newSuppliersSpending":
        spendingCategoryValue = newSuppliersSpending.value || 0;
        break;
      case "localCommunitiesSpending":
        spendingCategoryValue = localCommunitiesSpending.value || 0;
        break;
      case "innovativeTechnologiesSpending":
        spendingCategoryValue = innovativeTechnologiesSpending.value || 0;
        break;
      case "csrSpending":
        spendingCategoryValue = csrSpending.value || 0;
        break;
      default:
        spendingCategoryValue = 0;
    }

    // Format the quarter string
    const quarter = `${segment} ${year}`;
    return {
      quarter,
      value: spendingCategoryValue,
      quarterNumber: convertQuarterToNumber(quarter),
    };
  });

  // Sort the data by quarterNumber to ensure correct order
  transformedData.sort((a, b) => a.quarterNumber - b.quarterNumber);

  // Remove the helper property before returning the final data
  return transformedData.map(({ quarter, value }) => ({ quarter, value }));
}

// RADIAL CHART DATA
const formattedRadialData = (data) => {
  const { basicSectorSpecificKPI, environmentKPI, socialKPI, governanceKPI } =
    data;
  console.log(socialKPI);

  return {
    name: "flare",
    children: [
      {
        name: "BASE",
        children: [
          {
            name: `B1`,
            value: (basicSectorSpecificKPI?.B1?.score || 0).toFixed(2),
            kpi: "GHG emissions intensity",
          },
          {
            name: `B2`,
            value: (basicSectorSpecificKPI?.B2?.score || 0).toFixed(2),
            kpi: "Water Intensity",
          },
          {
            name: `B3`,
            value: (basicSectorSpecificKPI?.B3?.score || 0).toFixed(2),
            kpi: "Hazardous waste Intensity",
          },
          {
            name: `B4`,
            value: (basicSectorSpecificKPI?.B4?.score || 0).toFixed(2),
            kpi: "Non-hazardous waste Intensity",
          },
          {
            name: `B5`,
            value: (basicSectorSpecificKPI?.B5?.score || 0).toFixed(2),
            kpi: "Sustainability budget as a percentage of Revenue",
          },
          {
            name: `B6`,
            value: (basicSectorSpecificKPI?.B6?.score || 0).toFixed(2),
            kpi: "Percentage of electricity consumed that comes from clean sources plus purchase of REC certificates",
          },
        ],
      },
      {
        name: "ENVIRONMENTAL",
        children: [
          {
            name: `E1`,
            value: (environmentKPI?.E1?.score || 0).toFixed(2),
            kpi: "Scope 1 and Scope 2 - CO2e emissions",
          },
          {
            name: `E2`,
            value: (environmentKPI?.E2?.score || 0).toFixed(2),
            kpi: "Scope 3 - CO2e emissions",
          },
          {
            name: `E3`,
            value: (environmentKPI?.E3?.score || 0).toFixed(2),
            kpi: "Amount of Water Consumed",
          },
          {
            name: `E4`,
            value: (environmentKPI?.E4?.score || 0).toFixed(2),
            kpi: "Percentage of total waste disposed to landfill",
          },
          {
            name: `E5`,
            value: (environmentKPI?.E5?.score || 0).toFixed(2),
            kpi: "NOx released into the environment",
          },
          {
            name: `E6`,
            value: (environmentKPI?.E6?.score || 0).toFixed(2),
            kpi: "SOx released in the environment",
          },
          {
            name: `E7`,
            value: (environmentKPI?.E7?.score || 0).toFixed(2),
            kpi: "Threshold H2S concentration for the flare technology in use",
          },
          {
            name: `E8`,
            value: (environmentKPI?.E8?.score || 0).toFixed(2),
            kpi: "LA90 Noise level",
          },
          {
            name: `E9`,
            value: (environmentKPI?.E9?.score || 0).toFixed(2),
            kpi: "Percentage of waste water treated before being disposed",
          },
        ],
      },
      {
        name: "SOCIAL",
        children: [
          {
            name: `S1`,
            value: (socialKPI?.S1?.score || 10).toFixed(2),
            kpi: "Gender Ratio",
          },
          {
            name: `S2`,
            value: (socialKPI?.S2?.score || 10).toFixed(2),
            kpi: "Percentage of foreign employees",
          },
          {
            name: `S3`,
            value: (socialKPI?.S3?.score || 0).toFixed(2),
            kpi: "Percent of Direct to Indirect Employees",
          },
          {
            name: `S4`,
            value: (socialKPI?.S4?.score || 0).toFixed(2),
            kpi: "Average tenure of employees in the organisation",
          },
          {
            name: `S5`,
            value: (socialKPI?.S5?.score || 0).toFixed(2),
            kpi: "Hours of training provided to employees",
          },
          {
            name: `S6`,
            value: (socialKPI?.S6?.score || 0).toFixed(2),
            kpi: "Non-discriminatory policy in place",
          },
          {
            name: `S7`,
            value: (socialKPI?.S7?.score || 0).toFixed(2),
            kpi: "TRIR",
          },
          {
            name: `S8`,
            value: (socialKPI?.S8?.score || 0).toFixed(2),
            kpi: "Total number of employees hired through campus placements",
          },
        ],
      },
      {
        name: "GOVERNANCE",
        children: [
          {
            name: `G1`,
            value: (governanceKPI?.G1?.score || 0).toFixed(2),
            kpi: "Tax Relief/Incentives/Subsidies Received from the Government",
          },
          {
            name: `G2`,
            value: (governanceKPI?.G2?.score || 0).toFixed(2),
            kpi: "Percentage of pension salary contributed by employer",
          },
          {
            name: `G3`,
            value: (governanceKPI?.G3?.score || 0).toFixed(2),
            kpi: "Amount spent on local suppliers as a percentage of Gross Revenue",
          },
          {
            name: `G4`,
            value: (governanceKPI?.G4?.score || 0).toFixed(2),
            kpi: "Amount spent on suppliers having registered business less than 5 years as a percentage of Gross Revenue",
          },
          {
            name: `G5`,
            value: (governanceKPI?.G5?.score || 0).toFixed(2),
            kpi: "Amount spent on local communities as a percentage of Gross Revenue",
          },
          {
            name: `G6`,
            value: (governanceKPI?.G6?.score || 0).toFixed(2),
            kpi: "Amount spent on innovative technologies as a percentage of Gross Revenue",
          },
          {
            name: `G7`,
            value: (governanceKPI?.G7?.score || 0).toFixed(2),
            kpi: "Ethics policy in place",
          },
          {
            name: `G8`,
            value: (governanceKPI?.G8?.score || 0).toFixed(2),
            kpi: "Percentage of resolved complaints",
          },
          {
            name: `G9`,
            value: (governanceKPI?.G9?.score || 0).toFixed(2),
            kpi: "Number of Board members",
          },
          {
            name: `G10`,
            value: (governanceKPI?.G10?.score || 0).toFixed(2),
            kpi: "Amount spent on CSR activities as a percentage of Gross Revenue",
          },
        ],
      },
    ],
  };
};

export {
  transformDataForTrends,
  transformDataForGHGEmissions,
  transformDataForSoxNox,
  transformDataForElectricity,
  transformAndSortWaterConsumptionTrend,
  transformWasteDataForTrend,
  transformDataForWasteHorizontal,
  transformDataForComplaints,
  transformActionableData,
  transformGovernanceTrendData,
  transformSocialTrendData,
  transformIntensityTrendData,
  formattedRadialData,
};
