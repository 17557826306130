import React from "react";
import useFetch from "../../../utils/hooks/useFetch";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { Link, useNavigate } from "react-router-dom";
import { convertDateToDDMMYYYY } from "../../../utils/dateFunctions";

const Vetted = ({ search }) => {
  const navigate = useNavigate();
  const {
    data: vettedReports,
    loading: loadingVettedReports,
    error: vettedReportsErr,
  } = useFetch(reportEndpoints.getReports, {
    status: "vetted",
  });

  console.log(vettedReports);

  const rows = [
    {
      id: 0,
      reportName: "Report 1",
      submittedDate: "05-05-2024",
      status: "Verified",
    },
    {
      id: 1,
      reportName: "Report 2",
      submittedDate: "10-05-2024",
      status: "Not Verified",
    },
  ];

  const headings = ["Report Name", "Submitted Date", "Status", "Actions"];

  const filteredReports = vettedReports?.reports?.filter((item) =>
    item?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const vettingStatus = {
    vetted: "Verified",
  };

  return (
    <div className="reports-table-container">
      <div className="reports-table">
        <div className="reports-table-row reports-table-header">
          {headings.map((item) => (
            <div key={item} className="reports-table-cell">
              {item}
            </div>
          ))}
        </div>
        {filteredReports?.map((item) => (
          <div key={item?.id} className="reports-table-row">
            <div className="reports-table-cell">{item?.name}</div>
            <div className="reports-table-cell">
              {convertDateToDDMMYYYY(item?.updatedAt)}
            </div>
            <div
              className={`reports-table-cell ${
                item?.status !== "Verified"
                  ? "report-verified"
                  : "report-unverified"
              }`}
            >
              {vettingStatus[item?.status]}
            </div>
            <div className="reports-table-cell ">
              <Link to={`/score-card/${item?.id}`}>
                <button className="report-actions-btn">Preview</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vetted;
