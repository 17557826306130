import { createContext, useContext, useEffect, useState } from "react";
import { verifyEmailOTP, verifyEmailPassword } from "../../api/auth";
import api from "../../api";
import { userEndpoints } from "../../api/endpoints/user.endpoints";
import Loader from "../Loader";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const localToken = localStorage.getItem("token");
  const [token, setToken] = useState(localToken || "");
  const [isAuthenticated, setIsAuthenticated] = useState(!!localToken);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPageNotOTP, setIsPageNotOTP] = useState(true);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await api.get(userEndpoints.getUser);
      setUser(res.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.log(error);
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) fetchUser();
    else setLoading(false);
  }, [isPageNotOTP]);

  async function verifyOtpWithBackend(data) {
    try {
      const authenticatedUser = await verifyEmailOTP(data);
      if (authenticatedUser?.data?.data?.token) {
        const newToken = authenticatedUser.data.data.token;
        setToken(newToken);
        localStorage.setItem("token", newToken);
        setIsAuthenticated(true);
        // await fetchUser(); // Ensure the user data is fetched
        return authenticatedUser;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function verifyPasswordWithBackend(data) {
    try {
      const authenticatedUser = await verifyEmailPassword(data);
      if (authenticatedUser?.data?.data?.token) {
        const newToken = authenticatedUser.data.data.token;
        setToken(newToken);
        localStorage.setItem("token", newToken);
        setIsAuthenticated(true);
        await fetchUser(); // Ensure the user data is fetched
        setLoading(false);
        return authenticatedUser;
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  async function LogOutUser() {
    setToken("");
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.clear();
  }

  const value = {
    verifyOtpWithBackend,
    verifyPasswordWithBackend,
    isAuthenticated,
    logout: LogOutUser,
    user,
    loading,
    setIsAuthenticated,
    setIsPageNotOTP,
  };

  if (loading) return <Loader />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
