/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Grid, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import redI from "../../../../assets/redI.svg";
import AssignButton from "./AssignButton";
import { allKeyMappings as keyMapping } from "../../../../utils/rejectedReports";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
} from "../../../../utils/data-points/cleanObj";
import usePutApi from "../../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../../api/endpoints/report.endpoints";
import { useParams } from "react-router-dom";
import { useTaggingStore } from "../../dataPoints/assignments/chatStore";
import AssignModal from "../../dataPoints/assignments/AssignModal";
import constant from "../../../../constant";
import messages from "../../../../assets/messages.svg";
import dummyUser from "../../../../assets/dummyUser.png";

const RejectedTable = ({ title, reportData, unitsData, kpiName }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const params = useParams();
  const [dataToMap, setDataToMap] = useState({});
  const { allUsers, assignMembersBySymbol, fetchUsers } = useTaggingStore();

  useEffect(() => {
    fetchUsers(); // Fetch users once when component mounts
  }, []);

  useEffect(() => {
    const { id, ...other } = reportData;
    const mergedData = { ...other };
    setDataToMap(mergedData);
  }, [reportData]);

  const { put: saveProgress, error: saveError } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );

  const dynamicUnitMapping = unitsData?.data?.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const handleInputChange = (symbol, dataKey, value) => {
    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value:
              value === "true"
                ? true
                : value === "false"
                ? false
                : parseFloat(value), // Handle boolean values
          },
        },
      };
      // localStorage.setItem(localStorageKey, JSON.stringify(updatedData)); // Persist changes in localStorage
      // return updatedData;
      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ [kpiName]: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const [modalState, setModalState] = useState({
    visible: false,
    top: 0,
    left: 0,
    symbol: null, // Track which symbol triggered the modal
    code: "",
    kpiName: "",
    alreadyTagged: [],
  });

  const handleAssignClick = (event, symbol, alreadyTagged) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const maxModalHeight = 550; // Max height of the modal

    const viewportHeight = window.innerHeight; // Total available viewport height
    const spaceBelow = viewportHeight - buttonRect.bottom; // Space below the button
    const spaceAbove = buttonRect.top; // Space above the button

    // Decide whether to position the modal above or below the button
    let topPosition;
    let modalHeight; // The height of the modal

    if (spaceBelow >= maxModalHeight) {
      // Enough space below the button, place the modal below
      topPosition = buttonRect.bottom;
      modalHeight = maxModalHeight; // Max height because there's enough space below
    } else if (spaceAbove >= maxModalHeight) {
      // Not enough space below, but enough space above, place the modal above
      topPosition = buttonRect.top - maxModalHeight;
      modalHeight = maxModalHeight; // Max height because there's enough space above
    } else if (spaceBelow > spaceAbove) {
      // More space below, use available space below
      topPosition = buttonRect.bottom;
      modalHeight = spaceBelow; // Set height to available space below
    } else {
      // More space above, use available space above
      topPosition = buttonRect.top - spaceAbove;
      modalHeight = spaceAbove; // Set height to available space above
    }

    // Adjust left position, ensuring the modal fits within the viewport width
    const modalWidth = 500; // Assume modal width
    const leftPosition = Math.min(
      buttonRect.right - modalWidth,
      window.innerWidth - modalWidth
    );

    // Set modal state with dynamically calculated top, left, and height
    setModalState({
      visible: true,
      top: topPosition,
      left: leftPosition,
      symbol, // Store the clicked symbol
      height: modalHeight, // Dynamically set the height based on available space
      code: symbol,
      kpiName: kpiName,
      alreadyTagged,
    });
  };

  // Hide the modal
  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
      // top: 0,
      // left: 0,
      // symbol: null,
    }));
  };

  return (
    <div className="rejected-kpi-table-container">
      <h2 className="rejected-table_title">{title}</h2>

      <div className="rejected-kpi-table">
        <div className="rejected-kpi-table-body">
          {Object?.keys(dataToMap)?.map((symbol) => {
            if (!dataToMap[symbol]?.assign) {
              dataToMap[symbol] = {
                ...dataToMap[symbol],
                assign: { assignMembers: [] },
              };
            }

            const assignMembers =
              dataToMap[symbol]?.assign?.assignMembers || [];

            // Filter `teamMembers` to get only the members with IDs in `assignMembers`
            const assignedUsers =
              allUsers?.filter((member) => assignMembers.includes(member.id)) ||
              [];

            console.log(assignedUsers);

            if (symbolsToNotMap.includes(symbol)) return null;

            return (
              <div
                key={`symbol-${symbol}`}
                className="rejected-kpi-table-row rejected-kpi-table-body-row"
              >
                <div className="rejected-kpi-table-cell">
                  <div className="rejected-kpi-table-cell-content">
                    {symbol}
                  </div>
                </div>
                <div className="rejected-kpi-table-cell">
                  {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                    if (dataPointsToNotMap.includes(dataKey)) return null;

                    return (
                      <div
                        key={`rejected-data-points${idx}`}
                        className="rejected-kpi-table-cell-content data"
                        // Apply background color to individual data points
                      >
                        {keyMapping[dataKey] || dataKey}
                      </div>
                    );
                  })}
                </div>

                <div className="rejected-kpi-table-cell">
                  {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                    if (dataPointsToNotMap.includes(dataKey)) return null;

                    const unit =
                      dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];
                    const showUnit = unit === "0/1" ? "Yes/No" : unit;

                    return (
                      <div
                        key={idx}
                        className="rejected-kpi-table-cell-content data"
                      >
                        {showUnit || "N/A"}
                      </div>
                    );
                  })}
                </div>

                {/* INPUT VALUES */}
                <div className="rejected-kpi-table-cell">
                  {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                    if (dataPointsToNotMap.includes(dataKey)) return null;

                    const unit =
                      dynamicUnitMapping[dataToMap[symbol][dataKey].unit];
                    const value = dataToMap[symbol][dataKey].value;

                    if (unit === "0/1") {
                      return (
                        <div
                          className="new-kpi-table-cell-content input-wrapper"
                          key={idx}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <button
                              className={`yes-no-btn yes-btn ${
                                value === true ? "yes-btn-selected" : ""
                              }`}
                              onClick={() =>
                                handleInputChange(symbol, dataKey, "true")
                              }
                            >
                              Yes
                            </button>
                            <button
                              className={`yes-no-btn no-btn ${
                                value === false ? "no-btn-selected" : ""
                              }`}
                              onClick={() =>
                                handleInputChange(symbol, dataKey, "false")
                              }
                            >
                              No
                            </button>

                            <img
                              src={redI}
                              height={16}
                              width={16}
                              alt="Alert"
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={idx}
                          className="new-kpi-table-cell-content input-wrapper"
                          // Apply background color to individual input cells
                        >
                          <input
                            type="number"
                            value={dataToMap[symbol][dataKey]?.value || ""}
                            className="rejected-input-field"
                            placeholder="Enter value"
                            onChange={(e) =>
                              handleInputChange(symbol, dataKey, e.target.value)
                            }
                          />

                          {/* here to change */}

                          <Tooltip
                            title={(() => {
                              console.log("Tooltip Data:", {
                                dataToMap,
                                symbol,
                                approval: dataToMap?.[symbol]?.approval,
                              });

                              if (
                                dataToMap?.[symbol]?.approval?.status ===
                                "rejected"
                              ) {
                                return dataToMap[symbol].approval.message;
                              }

                              return "This value has been rejected";
                            })()}
                            arrow
                            placement="bottom-end"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "#FFFFFF",
                                  color: "#EE5447",
                                  // width: "331px",
                                  // height: "51px",
                                  padding: "16px",
                                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.14)",
                                  "& .MuiTooltip-arrow": {
                                    color: "#FFFFFF",
                                  },
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "14.52px",
                                  textAlign: "left",
                                  textDecorationSkipInk: "none",
                                  textUnderlinePosition: "under",
                                },
                              },
                            }}
                          >
                            <img
                              src={redI}
                              height={16}
                              width={16}
                              alt="Alert"
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px", // Keep the margin where it was previously applied
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="rejected-kpi-table-cell">
                  <div className="rejected-kpi-table-cell-content">
                    {dataToMap[symbol]?.assign?.assignMembers?.length > 0 ? (
                      <div className="messages-wrapper">
                        <div className="profile-images-container">
                          {/* Show the first 3 profile images */}
                          {assignedUsers?.slice(0, 2)?.map((img) => (
                            <img
                              key={`${constant.IMG_URL}/${img.profileImage}`}
                              src={
                                `${constant.IMG_URL}/${img.profileImage}` ??
                                dummyUser
                              }
                              alt="Messaged"
                              height={28}
                              width={28}
                            />
                          ))}

                          {/* Show the count of remaining users if more than 3 */}
                          {assignedUsers.length > 2 && (
                            <div className="remaining-count">
                              +{assignedUsers.length - 2}
                            </div>
                          )}
                        </div>
                        <div
                          onClick={(event) =>
                            handleAssignClick(event, symbol, assignMembers)
                          }
                          style={{ cursor: "pointer" }}
                          className="notifications-div"
                        >
                          <img
                            src={messages}
                            alt="Messaged"
                            height={24}
                            width={24}
                          />
                          <span className="unread">2 Unread</span>
                        </div>
                      </div>
                    ) : (
                      <AssignButton
                        text="Assign"
                        onClick={(event) =>
                          handleAssignClick(event, symbol, assignMembers)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <AssignModal
        closeModal={closeModal}
        modalState={modalState}
        // refetch={refetch}
      />
    </div>
  );
};

export default RejectedTable;
