import React, { createContext, useContext } from "react";
import { organizationEndpoints } from "../api/apiEndpoints";
import useFetch from "../utils/hooks/useFetch";
import { userEndpoints } from "../api/endpoints/user.endpoints";

// Create the context
export const OrgContext = createContext();

export default function useOrganizationContext() {
  return useContext(OrgContext);
}

export const OrgContextProvider = ({ children }) => {
  // Use the useFetch hook to fetch organization data
  const {
    data,
    loading,
    error,
    refetch: refetchOrg,
  } = useFetch(organizationEndpoints.getOrganization);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: refetchUser,
  } = useFetch(userEndpoints.getUser);

  console.log(userData);

  // Render the children components and provide the organization data through context
  return (
    <OrgContext.Provider
      value={{ data, loading, error, userData, refetchOrg, refetchUser }}
    >
      {children}
    </OrgContext.Provider>
  );
};
